<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, reactive, watch, computed, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAccountStore } from '@/store/account'
import { useLocationStore } from '@/store/location'
import { useMapsStore } from '@/store/maps'
import { useNotificationsStore } from '@/store/notifications'
import { storeToRefs } from 'pinia'
import { formattedAddress, locationTypes, features } from '@/utils/location'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from '@headlessui/vue'
import Icon from '@/components/Icon.vue'
import TtButton from '../TtButton.vue'
import TtContainer from '../TtContainer.vue'
import TtAccordion from '../TtAccordion.vue'
import TtSiteOwner from '../TtSiteOwner.vue'
import TtFeatureToggle from '../TtFeatureToggle.vue'
import TtListbox from '../TtListbox.vue'
import SiteOwnerParkingSpots from '../SiteOwnerParkingSpots.vue'
import TtPhoneInput from '../TtPhoneInput.vue'
import IconSearch from '../Icon/Search.vue'
import IconClose from '../Icon/Close.vue'
import IconTrash from '../Icon/Trash.vue'

import type { Place } from '@/maps'
import type {
  Location,
  Meta,
  LocationFeature,
  LocationFeatures
} from '@/location'
import type { RequestError } from '@/types'

const account = useAccountStore()
const locations = useLocationStore()
const maps = useMapsStore()
const notificationsStore = useNotificationsStore()

const { t } = useI18n({ useScope: 'global' })
const { user } = storeToRefs(account)
const { map } = storeToRefs(maps)
const { tempAreaFeatureCollection, parkings } = storeToRefs(locations)

const route = useRoute()
const router = useRouter()

const props = defineProps<{
  location: Location
}>()
const emit = defineEmits(['changeLocation'])

const locationTypeOptions = locationTypes.filter((type) => {
  if (user.value?.role !== 'siteowner') return true
  return type.value === 'paid_parking'
})

const modalOpen = ref(false)
const locationName = ref(props.location?.name ?? '')
const isCompany = ref<boolean>(false)
const isFavorite = ref<boolean>(false)
const selectedAddress = ref<Place | null>(
  props.location
    ? {
        place_name: props.location.name,
        center: [props.location.coordinates.lng, props.location.coordinates.lat]
      }
    : null
)
const selectedType = ref(locationTypeOptions[0])
const suggestions = ref<Place[]>([])
const query = ref('')
const updatingLocation = ref(false)
const checkInText = ref('')
const checkOutText = ref('')
const contactPhone = ref('')
const locationFeatures = reactive<LocationFeatures>(features)
locationFeatures.accessibility.onCheck = (feature: LocationFeature) => {
  for (const child of locationFeatures.accessibility.children) {
    if (child.key === feature.key) {
      child.checked = true // Check the clicked child
    } else {
      child.checked = false // Uncheck all other children
    }
  }
}

const locationMeta = computed(() => {
  const meta = {} as { [key: string]: any }
  props.location?.meta.forEach((item) => {
    if (typeof item.value === 'string') {
      meta[item.type] = item.value
    } else {
      if (item.type === 'comfort') {
        if (!meta[item.type]) meta[item.type] = {}
        meta[item.type][item.value.key] = item.value.value ?? 'true'
      } else if (item.type === 'openhours') {
        meta[item.type] = JSON.parse(item.value.key)
      } else {
        meta[item.type] = item.value.key
      }
    }
  })
  return meta
})

const saveLocationArea = () => {
  const featureCollection = map.value?.drawInstance?.getAll()
  if (featureCollection) {
    if (featureCollection.features[0]?.geometry?.coordinates?.[0][0]) {
      tempAreaFeatureCollection.value = featureCollection
    }
  }
  map.value?.stopDrawing()
}

const save = async (privateLocation = false) => {
  if (updatingLocation.value) return
  if (map.value?.isDrawing) {
    saveLocationArea()
  }
  if (parkings.value.length > 0) {
    let valid = true
    parkings.value.forEach((parking) => {
      if (!parking.price || parking.price < 0) {
        notificationsStore.addNotification(
          'location-validation-error',
          t('location.parkingPriceError'),
          'error',
          true,
          false,
          false,
          false
        )
        valid = false
        return
      }
    })
    if (!valid) return
  }
  notificationsStore.addNotification(
    'location-saving',
    t('location.saving'),
    'notification',
    false,
    false,
    false,
    false
  )
  updatingLocation.value = true

  const newLocation = { ...props.location } as Location

  if (!newLocation.id) {
    // Temporary subomptimal solution to get the correct address until we get access to Geocoding v6
    const addressParts = newLocation.name.split(',').map((part) => part.trim())
    newLocation.address.street = addressParts[0]
    newLocation.address.zip = addressParts[1].split(' ').slice(0, 2).join(' ')
    newLocation.address.city = addressParts[1].split(' ')[2]
    newLocation.address.country = addressParts[2]
  }
  newLocation.name = locationName.value
  const comforts = locationFeatures.comforts.children.filter(
    (item) => item.checked
  )
  const accessibility = locationFeatures.accessibility.children.filter(
    (item) => item.checked
  )
  const openhours = locationFeatures.openhours.children.filter(
    (item) => item.checked
  )
  newLocation.meta = comforts.reduce((acc, item) => {
    if (item.children) {
      item.children.forEach((child) => {
        if (child.checked) {
          let value = 'true'
          if (child.value) {
            value = child.value
            if (child.type === 'price' || child.type === 'number') {
              value = value.toString()
            }
          }
          acc.push({
            type: 'comfort',
            value: {
              key: child.key,
              value: value
            }
          })
        }
      })
    }
    acc.push({
      type: 'comfort',
      value: { key: item.key, value: 'true' }
    })
    return acc
  }, [] as Meta[])
  newLocation.meta = newLocation.meta.concat(
    accessibility.reduce((acc, item) => {
      acc.push({
        type: 'accessibility',
        value: { key: item.key, value: 'true' }
      })
      return acc
    }, [] as Meta[])
  )
  const openinghours = {} as { [key: string]: any }
  const allweek = openhours.find((item) => item.key === 'allweek')
  if (allweek) {
    const allWeekChildren = allweek.children?.filter((item) => item.checked)
    if (allWeekChildren && allWeekChildren.length > 0) {
      if (allWeekChildren.find((item) => item.key === '24h')) {
        openinghours['allweek'] = { allday: true }
      } else {
        const opens = allWeekChildren.find((item) => item.key === 'opens')
        const closes = allWeekChildren.find((item) => item.key === 'closes')
        if (opens && closes) {
          openinghours['allweek'] = { opens: opens.value, closes: closes.value }
        }
      }
    }
  } else {
    openhours.forEach((item) => {
      const dayChildren = item.children?.filter((item) => item.checked)
      if (dayChildren && dayChildren.length > 0) {
        if (dayChildren.find((item) => item.key === '24h')) {
          openinghours[item.key] = { allday: true }
        } else {
          const opens = dayChildren.find((item) => item.key === 'opens')
          const closes = dayChildren.find((item) => item.key === 'closes')
          if (opens && closes) {
            openinghours[item.key] = {
              opens: opens.value,
              closes: closes.value
            }
          }
        }
      }
    })
  }

  if (Object.keys(openinghours).length > 0) {
    openinghours['timezoneOffset'] = new Date().getTimezoneOffset()
    newLocation.meta.push({
      type: 'openhours',
      value: { key: JSON.stringify(openinghours) }
    })
  }

  newLocation.meta = newLocation.meta.concat([
    {
      type: 'trucktrust_verified',
      value: { key: 'false' }
    },
    {
      type: 'private',
      value: { key: privateLocation.toString() }
    },
    {
      type: 'company',
      value: { key: isCompany.value.toString() }
    },
    {
      type: 'type',
      value: { key: selectedType.value.value }
    }
  ] as Meta[])

  if (selectedType.value.value === 'restaurant') {
    newLocation.meta.push({
      type: 'comfort',
      value: { key: 'food' }
    })
  }

  if (
    ['laundry', 'store', 'toilet', 'shower'].includes(selectedType.value.value)
  ) {
    newLocation.meta.push({
      type: 'comfort',
      value: { key: selectedType.value.value }
    })
  }

  if (user.value?.role === 'siteowner' && tempAreaFeatureCollection.value) {
    newLocation.meta.push({
      type: 'area',
      value: { key: JSON.stringify(tempAreaFeatureCollection.value) }
    })
  } else if (props.location.meta.find((item) => item.type === 'area')) {
    newLocation.meta.push(
      props.location.meta.find((item) => item.type === 'area') as Meta
    )
  }

  if (checkInText.value) {
    newLocation.meta.push({
      type: 'checkin',
      value: { key: checkInText.value }
    })
  }

  if (checkOutText.value) {
    newLocation.meta.push({
      type: 'checkout',
      value: { key: checkOutText.value }
    })
  }

  if (contactPhone.value) {
    newLocation.meta.push({
      type: 'contact',
      value: {
        key: contactPhone.value
      }
    })
  }

  newLocation.dataset = selectedType.value.value

  const res = await locations.updateLocation(newLocation)
  notificationsStore.removeNotification('location-saving')
  if (res.error) {
    notificationsStore.addNotification(
      'location-save-error',
      t('location.saveError'),
      'error',
      true,
      false,
      false,
      false
    )
    setTimeout(() => {
      notificationsStore.removeNotification('location-save-error')
    }, 2000)
    updatingLocation.value = false
    return
  }
  await locations.updateAggregations()
  notificationsStore.addNotification(
    'location-saved',
    t('location.saved'),
    'notification',
    true,
    false,
    false,
    true
  )
  setTimeout(() => {
    notificationsStore.removeNotification('location-saved')
  }, 2000)
  if (locations.pin) {
    locations.pin.remove()
  }
  updatingLocation.value = false
  router.push('/location/' + res.id)
}

const remove = async () => {
  modalOpen.value = false
  if (updatingLocation.value) return
  if (map.value?.isDrawing) {
    map.value?.stopDrawing()
  }
  notificationsStore.addNotification(
    'location-removing',
    t('location.removing'),
    'notification',
    false,
    false,
    false,
    false
  )
  updatingLocation.value = true

  const res = await locations.removeLocation(props.location.id as string)
  notificationsStore.removeNotification('location-removing')
  if (res.error) {
    notificationsStore.addNotification(
      'location-remove-error',
      t('location.removeError'),
      'error',
      false,
      false,
      false,
      false
    )
    setTimeout(() => {
      notificationsStore.removeNotification('location-remove-error')
    }, 2000)
    updatingLocation.value = false
    return
  }
  await locations.updateAggregations()
  notificationsStore.addNotification(
    'location-removed',
    t('location.removed'),
    'notification',
    true,
    false,
    false,
    true
  )
  setTimeout(() => {
    notificationsStore.removeNotification('location-removed')
  }, 2000)
  if (locations.pin) {
    locations.pin.remove()
  }
  updatingLocation.value = false
  router.push('/')
}

const toggleParkingSection = (e: any) => {
  map.value?.toggleParkingSpots(e)
}
const changeLocation = (place: Place) => {
  emit('changeLocation', place)
}

const handleDrawButtonClick = () => {
  if (map.value?.isDrawing) {
    saveLocationArea()
  } else {
    map.value?.startDrawing()
  }
}

onBeforeUnmount(() => {
  if (map.value?.isDrawing) {
    map.value?.stopDrawing()
  }
  map.value?.toggleParkingSpots(false)
})

watch(query, async () => {
  if (query.value.length < 3) {
    suggestions.value = []
  } else {
    const resp = await maps.search({
      query: query.value,
      lang: 'sv',
      proximity: 'ip',
      limit: 10
    })
    if ((resp as Place[])?.length) {
      suggestions.value = resp as Place[]
    } else if ((resp as RequestError)?.error) {
      console.error(resp)
    } else {
      suggestions.value = []
    }
  }
})

watch(
  () => props.location?.name,
  (name) => {
    locationName.value = name ?? ''
  }
)

watch(
  () => props.location,
  async (loc: Location | null) => {
    if (!loc) return
    // Search for nearby places, if we use the whole name we get undesired results
    // Doesn't work great...
    const addressParts = loc.name.split(',').map((part) => part.trim())
    const streetWithNumber = addressParts[0]
    const streetParts = streetWithNumber.split(' ')
    let streetWithoutNumber = ''
    if (streetParts.length > 1) {
      streetWithoutNumber = streetParts.slice(0, -1).join(' ')
    } else {
      streetWithoutNumber = streetParts[0]
    }
    const city = addressParts[1].split(' ')[2]
    const resp = await maps.search({
      query: streetWithoutNumber + ' ' + city,
      lang: 'sv',
      proximity: loc.coordinates.lng + ',' + loc.coordinates.lat,
      limit: 5
    })
    if ((resp as Place[])?.length) {
      suggestions.value = resp as Place[]
    } else if ((resp as RequestError)?.error) {
      console.error(resp)
    } else {
      suggestions.value = []
    }
  }
)

watch(
  locationMeta,
  (meta) => {
    if (!meta) return
    selectedType.value =
      locationTypeOptions.find((type) => type.value === meta.type) ??
      locationTypeOptions[0]
    locationFeatures.comforts.children.forEach((item) => {
      if (meta.comfort?.[item.key]) {
        item.checked = true
        if (item.children) {
          item.children.forEach((child) => {
            if (meta.comfort[child.key]) {
              child.checked = true
              let value = meta.comfort[child.key]
              if (child.type === 'price' || child.type === 'number') {
                value = parseFloat(value)
              }
              child.value = value
            }
          })
        }
      }
    })
    locationFeatures.accessibility.children.forEach((item) => {
      if (meta.accessibility === item.key) {
        item.checked = true
      } else {
        item.checked = false
      }
    })
    locationFeatures.openhours.children.forEach((item) => {
      const allday = item.children?.find((child) => child.key === '24h')
      const opens = item.children?.find((child) => child.key === 'opens')
      const closes = item.children?.find((child) => child.key === 'closes')
      if (meta.openhours) {
        if (meta.openhours[item.key]) {
          item.checked = true
          if (allday && meta.openhours[item.key].allday) {
            allday.checked = true
          }
          if (opens && meta.openhours[item.key].opens) {
            opens.checked = true
            opens.value = meta.openhours[item.key].opens
          }
          if (closes && meta.openhours[item.key].closes) {
            closes.checked = true
            closes.value = meta.openhours[item.key].closes
          }
        }
      }
    })
    checkInText.value = meta.checkin ?? ''
    checkOutText.value = meta.checkout ?? ''
    if (meta.contact) {
      contactPhone.value = meta.contact
    }
  },
  { immediate: true }
)
</script>

<template>
  <h2
    v-if="route.name === 'location'"
    class="mb-4 text-2xl font-semibold tracking-tight"
  >
    {{ t('location.addNewLocation') }}
  </h2>
  <h2 v-else class="mb-4 text-2xl font-semibold tracking-tight">
    {{ t('location.editLocation') }}
  </h2>
  <label class="cursor-pointer text-lg font-medium tracking-tight">
    <span>{{ t('location.name') }}</span>
    <input
      type="text"
      :placeholder="locationName"
      :class="`form-input mb-3 mt-1 w-full overflow-hidden rounded border border-black/10 bg-transparent px-3 py-3.5 text-base ${
        locationName === location?.name ? 'text-tt-gray/60' : 'text-tt-gray'
      } font-light transition-colors placeholder:text-base placeholder:font-light placeholder:text-tt-gray/60 focus:border-tt-green focus:outline-none focus:ring-0`"
      v-model="locationName"
    />
  </label>
  <div class="mb-6">
    <label
      v-if="!user || user.role !== 'siteowner'"
      class="cursor-pointer text-base font-normal tracking-tight"
    >
      <input
        type="checkbox"
        class="form-checkbox mr-2 h-4.5 w-4.5 cursor-pointer rounded border border-tt-light-gray bg-transparent text-tt-gray transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
        v-model="isCompany"
      />
      {{ t('location.isCompany') }}
    </label>
    <template v-else-if="map">
      <button @click="handleDrawButtonClick" class="flex items-center gap-2">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.5"
            y="3.5"
            width="11"
            height="11"
            rx="3.5"
            stroke="black"
            stroke-dasharray="4 4"
          />
          <circle cx="3.5" cy="14.5" r="3.5" fill="white" />
          <circle
            cx="3.5"
            cy="14.5"
            r="3"
            stroke="black"
            stroke-opacity="0.23"
          />
          <circle cx="14.5" cy="3.5" r="3.5" fill="white" />
          <circle
            cx="14.5"
            cy="3.5"
            r="3"
            stroke="black"
            stroke-opacity="0.23"
          />
        </svg>

        <template v-if="map.isDrawing">
          {{ t('location.stopDrawing') }}
        </template>
        <template v-else>
          {{ t('location.drawArea') }}
        </template>
      </button>
    </template>
  </div>
  <label
    for="locationAddress"
    class="cursor-pointer text-lg font-medium tracking-tight"
  >
    <div class="mb-1 flex w-full items-center justify-between">
      <span>{{ t('location.address') }}</span>
      <span class="text-sm font-light">
        Lat. {{ location.coordinates.lat.toFixed(6) }}, Long.
        {{ location.coordinates.lng.toFixed(6) }}
      </span>
    </div>
  </label>
  <div class="relative mb-6">
    <template v-if="!location?.id">
      <Combobox v-model="selectedAddress">
        <IconSearch class="absolute left-3 top-4.5 text-tt-gray" />
        <ComboboxInput
          id="locationAddress"
          class="form-input w-full resize-none rounded border border-black/10 bg-transparent px-9 py-3.5 text-base font-light tracking-tight text-tt-gray transition-colors placeholder:text-tt-gray focus:border-tt-green focus:outline-none focus:ring-0"
          @change="query = $event.target.value"
          :placeholder="location?.name ?? formattedAddress(location)"
          @keyup.enter="changeLocation(suggestions[0])"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-1 flex items-center pr-2"
        >
          <svg
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3125 1.06261C10.3125 1.23852 10.2454 1.41452 10.1111 1.54858L5.98608 5.67358C5.71753 5.94214 5.28247 5.94214 5.01392 5.67358L0.888916 1.54858C0.620361 1.28003 0.620361 0.844971 0.888916 0.576416C1.15747 0.307862 1.59253 0.307862 1.86108 0.576417L5.5 4.21544L9.13945 0.575987C9.40801 0.307432 9.84307 0.307432 10.1116 0.575987C10.2459 0.710264 10.3125 0.886436 10.3125 1.06261Z"
              fill="#374650"
            />
          </svg>
        </ComboboxButton>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ComboboxOptions
            class="absolute z-10 mt-3 max-h-40 w-full overflow-auto rounded bg-white shadow-sidebar outline-none"
          >
            <div
              v-for="(suggestion, index) in suggestions"
              :key="index"
              :class="`${
                index < suggestions.length - 1
                  ? 'border-tt-gray/26 border-b'
                  : ''
              }`"
            >
              <ComboboxOption
                as="template"
                v-slot="{ active }"
                :value="suggestion"
              >
                <button
                  :class="`flex w-full cursor-pointer items-center justify-between rounded px-3 py-3 text-left text-base ${
                    active && 'ring-1 ring-inset ring-tt-green'
                  }`"
                  @click="changeLocation(suggestion)"
                >
                  {{ suggestion.place_name }}
                </button>
              </ComboboxOption>
            </div>
          </ComboboxOptions>
        </TransitionRoot>
      </Combobox>
    </template>
    <template v-else>
      {{ formattedAddress(location) }}
    </template>
  </div>
  <label
    for="locationType"
    class="cursor-pointer text-lg font-medium tracking-tight"
  >
    <span>{{ t('location.type') }}</span>
  </label>
  <div class="relative mb-6 mt-1">
    <TtListbox
      :dark="true"
      :locationFeature="true"
      :options="locationTypeOptions"
      :defaultValue="selectedType"
      :optionDisplay="'label'"
      @update="selectedType = $event"
    >
      <div class="flex items-center gap-2 text-tt-gray">
        <Icon
          :assets="[
            { name: selectedType.value, type: 'icon-map', ext: '.svg' }
          ]"
          :priority="['icon-map']"
        />
        <span>{{ selectedType.label }}</span>
      </div>
      <template #option="{ option }">
        <div class="flex items-center gap-2 text-tt-gray">
          <Icon
            :assets="[{ name: option.value, type: 'icon-map', ext: '.svg' }]"
            :priority="['icon-map']"
          />
          {{ option.label }}
        </div>
      </template>
    </TtListbox>
  </div>
  <div class="mb-5 grid gap-2.5 pb-11" v-if="user?.role !== 'siteowner'">
    <TtButton
      :as="'button'"
      :size="'large'"
      :type="'primary'"
      :disabled="updatingLocation"
      class="!flex-grow px-8 tracking-tighter"
      @click.prevent="save()"
    >
      {{ t('location.save') }}
    </TtButton>
    <TtButton
      :as="'button'"
      :size="'large'"
      :type="'tertiary'"
      :disabled="updatingLocation"
      class="!flex-grow px-8 tracking-tighter"
      @click.prevent="save(true)"
    >
      {{ t('location.savePrivate') }}
    </TtButton>
    <div class="mb-6">
      <label class="cursor-pointer text-base font-normal tracking-tight">
        <input
          type="checkbox"
          class="form-checkbox mr-2 h-4.5 w-4.5 cursor-pointer rounded border border-tt-light-gray bg-transparent text-tt-gray transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
          v-model="isFavorite"
        />
        {{ t('location.saveFavorite') }}
      </label>
    </div>
  </div>
  <div v-if="user?.role === 'siteowner'">
    <template v-if="selectedType.value === 'paid_parking'">
      <div class="mb-7 border-t border-black/10 pt-8">
        <TtAccordion :closed="true" @toggle="toggleParkingSection">
          <template v-slot:accordionButton>
            <div class="flex items-center">
              <h2 class="mr-2 text-lg font-semibold leading-9 tracking-tight">
                {{ t('location.paidParkingSpots') }}
              </h2>
              <TtSiteOwner />
            </div>
          </template>
          <div class="mt-4">
            <SiteOwnerParkingSpots
              :location="location"
              @update="(upd: any) => { console.log(upd)}"
            />
          </div>
        </TtAccordion>
      </div>
      <div class="mb-7 border-t border-black/10 pt-8">
        <TtAccordion :closed="true">
          <template v-slot:accordionButton>
            <div class="flex items-center">
              <h2 class="mr-2 text-lg font-semibold leading-9 tracking-tight">
                {{ t('location.locationInfo') }}
              </h2>
              <TtSiteOwner />
            </div>
          </template>
          <div>
            <label
              for="checkin"
              class="mb-2 mt-4 block cursor-pointer font-semibold tracking-tight"
            >
              {{ t('location.checkin') }}
            </label>
            <textarea
              id="checkin"
              class="form-textarea h-[8.75rem] w-full resize-none rounded border border-black/10 bg-transparent p-2 text-base font-light tracking-tight text-tt-gray transition-colors placeholder:text-tt-gray/60 focus:border-tt-green focus:outline-none focus:ring-0"
              v-model="checkInText"
            >
            </textarea>
            <label
              for="checkout"
              class="mb-2 mt-4 block cursor-pointer font-semibold tracking-tight"
            >
              {{ t('location.checkout') }}
            </label>
            <textarea
              id="checkout"
              class="form-textarea h-[8.75rem] w-full resize-none rounded border border-black/10 bg-transparent p-2 text-base font-light tracking-tight text-tt-gray transition-colors placeholder:text-tt-gray/60 focus:border-tt-green focus:outline-none focus:ring-0"
              v-model="checkOutText"
            ></textarea>
            <label
              for="contact"
              class="mb-2 mt-4 block cursor-pointer font-semibold tracking-tight"
            >
              {{ t('location.contact') }}
            </label>
            <TtPhoneInput v-model="contactPhone" />
          </div>
        </TtAccordion>
      </div>
    </template>
  </div>
  <div
    v-for="section in locationFeatures"
    :key="section.name"
    class="mb-7 border-t border-black/10 pt-8"
  >
    <TtAccordion :closed="true">
      <template v-slot:accordionButton>
        <h2 class="text-lg font-semibold leading-9 tracking-tight">
          {{ section.name }}
        </h2>
      </template>
      <TtFeatureToggle
        v-for="item in section.children"
        :item="item"
        :onCheck="section.onCheck"
        v-model="item.checked"
      />
    </TtAccordion>
  </div>
  <div class="border-t border-black/10 py-8">
    <TtButton
      v-if="location?.id"
      :as="'button'"
      :size="'large'"
      :type="'danger'"
      :disabled="updatingLocation"
      class="!flex-grow px-8 tracking-tighter"
      @click.prevent="modalOpen = true"
    >
      {{ t('location.removeLocation') }}
    </TtButton>
    <TtButton
      v-if="user?.role === 'siteowner'"
      :as="'button'"
      :size="'large'"
      :type="'primary'"
      :disabled="updatingLocation"
      class="mt-8 !flex-grow px-8 tracking-tighter"
      @click.prevent="save()"
    >
      {{ t('location.saveAndPublish') }}
    </TtButton>
  </div>
  <TtContainer
    @set-open="(val) => (modalOpen = val)"
    variant="popup-card-bottom"
    :open="modalOpen"
  >
    <template #header>
      <div class="relative grid w-full grid-cols-10 px-5 pb-2 pt-5">
        <h2
          class="col-span-9 text-2xl font-semibold leading-7 tracking-tight text-black"
        >
          {{ t('location.removeReviewConfirmation') }}
        </h2>
        <button @click="modalOpen = false">
          <IconClose class="ml-auto mr-0 h-3.5 w-3.5 text-tt-gray" />
        </button>
      </div>
      <p class="px-5 text-lg font-light tracking-tight">
        {{ t('location.noGoingBack') }}
      </p>
    </template>
    <div class="overflow-auto px-5 pb-8">
      <div class="mt-7 grid gap-4">
        <TtButton @click="remove" size="large" type="danger" as="button">
          <IconTrash class="mr-1" />
          {{ t('location.yesRemoveLocation') }}
        </TtButton>
        <TtButton
          @click="modalOpen = false"
          size="large"
          type="tertiary"
          as="button"
        >
          {{ t('location.cancel') }}
        </TtButton>
      </div>
    </div>
  </TtContainer>
</template>
