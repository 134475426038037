<script lang="ts" setup>
import debounce from 'lodash/debounce'
import { useSidebarStore } from '@/store/sidebars'
import type { Place } from '@/maps'
import type { RequestError } from '@/types'
import { useMapsStore } from '@/store/maps'
import { useProfileStore } from '@/store/profile'
import { ref, computed, watch, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import ProfilePicture from '../Icon/ProfilePicture.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const sidebarStore = useSidebarStore()
const store = useMapsStore()

const openSidebar = () => {
  if (sidebarStore.open) return
  sidebarStore.toggleSidebar()
}

const { currentLocation } = storeToRefs(store)
const { uploadCount } = storeToRefs(useProfileStore())

const suggestions = ref<Place[]>([])
const query = ref('')
const active = ref<Boolean>(false)

const onChange = debounce((value: string) => {
  query.value = value
  active.value = true
}, 500)

const onBlur = () => {
  setTimeout(() => (active.value = false), 200)
}

const updatePlace = (index: number, place: Place) => {
  console.log(index, place)
  if (!place?.center) return
  currentLocation.value = place?.center
}

watch(query, async () => {
  if (query.value.length < 3) {
    suggestions.value = []
  } else {
    const resp = await store.search({
      query: query.value,
      lang: 'sv',
      proximity: 'ip',
      limit: 10
    })
    if ((resp as Place[])?.length) {
      suggestions.value = resp as Place[]
    } else if ((resp as RequestError)?.error) {
      console.error(resp)
    } else {
      suggestions.value = []
    }
  }
})
</script>

<template>
  <form class="flex-grow" @submit.prevent="() => {}">
    <div class="relative">
      <input
        class="form-input w-full rounded-full border border-tt-pale/25 bg-transparent py-2 pl-9 pr-9 text-lg font-light text-white transition-colors placeholder:text-white focus:border-tt-green focus:outline-none focus:ring-0"
        type="search"
        autocomplete="false"
        @input="($event) => onChange($event.target.value)"
        @blur="onBlur"
        @focus="openSidebar"
      />
      <div
        v-if="active && suggestions.length > 0"
        class="absolute z-20 mt-1 max-h-40 w-full max-w-full overflow-auto rounded bg-black shadow-sidebar outline-none"
      >
        <div
          v-for="(suggestion, sIndex) in suggestions"
          :key="sIndex"
          :class="`${
            sIndex < suggestions.length - 1 ? 'border-b border-tt-gray' : ''
          }`"
        >
          <span
            :class="`flex w-full cursor-pointer items-center justify-between rounded px-3 py-3 text-left text-base`"
            @click="updatePlace(sIndex, suggestion)"
          >
            {{ suggestion.place_name }}
          </span>
        </div>
      </div>
      <span class="absolute inset-y-0 left-0 flex items-center pl-3 pr-2">
        <svg
          class="h-4 w-4 text-white"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m15.753 14.719-4.188-4.188c.907-1.11 1.407-2.506 1.407-4.031a6.5 6.5 0 0 0-6.5-6.5C2.882 0 0 2.91 0 6.5S2.91 13 6.472 13a6.462 6.462 0 0 0 4.03-1.408l4.188 4.188c.175.148.37.22.56.22a.75.75 0 0 0 .53-.22.73.73 0 0 0-.027-1.061ZM1.5 6.5c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5-5-2.244-5-5Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <RouterLink
        class="absolute right-1.5 top-1/2 -translate-y-1/2"
        to="/profile"
      >
        <span class="sr-only">{{ t('profile.goToProfile') }}</span>
        <ProfilePicture
          :key="uploadCount"
          class="h-8 w-8 flex-shrink-0 flex-grow-0 rounded-full"
        />
      </RouterLink>
    </div>
  </form>
</template>
