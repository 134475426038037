<script setup lang="ts">
// If we do not like it we'll replace it with a 3rd party datepicker
import { v4 as uuidv4 } from 'uuid'
import { nextTick, ref, watch } from 'vue'
import { format } from 'date-fns'
import type { ParkingBooking } from '@/location';

// Assign a unique ID to each calendar instance
const id = uuidv4()

// Define props
const props = defineProps<{
  isDisabled?: boolean
  disabledDates?: any[]
  bookings?: ParkingBooking[]
  rules?: any
  minDate?: Date
  maxDate?: Date
  align?: 'left' | 'right'
}>()

// Define emits
const emit = defineEmits(['setDate', 'toggleCalendars'])
const setDate = (date: Date) => emit('setDate', date)

// Define refs
const selectedDate = ref<Date | undefined>(new Date())
selectedDate.value?.setMinutes(0)
const showCalendar = ref<boolean>(false)

// Define exposed methods
const close = () => {
  showCalendar.value = false
}
defineExpose({ close })

// Watch for changes in the selected date
watch(selectedDate, (newDate) => {
  nextTick(() => {
    if (newDate === undefined) return
    setDate(newDate)
  })
})

watch(showCalendar, (newShowCalendar) => {
  nextTick(() => {
    if (newShowCalendar === true) {
      emit('toggleCalendars')
    }
  })
})
</script>

<template>
  <div>
    <label :for="`calendar-input-${id}`" class="block text-lg font-medium">
      <slot />
    </label>
    <div class="relative mt-2" @keyup.esc="showCalendar = false">
      <input
        :id="`calendar-input-${id}`"
        type="hidden"
        v-model="selectedDate"
      />
      <button
        ref="calendarButton"
        :disabled="isDisabled"
        class="form-input text-lg w-full resize-none rounded border border-tt-light-gray bg-transparent py-3 pl-3 pr-10 text-left font-light leading-relaxed text-tt-gray transition-colors placeholder:text-tt-gray focus:border-tt-green focus:outline-none focus:ring-0 disabled:opacity-50"
        @click.prevent="!isDisabled && (showCalendar = true)"
      >
        <span v-if="selectedDate">
          {{ format(selectedDate, 'yyyy-MM-dd') }}
        </span>
        <span v-else>
          {{ format(new Date(), 'yyyy-MM-dd') }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="19"
          fill="none"
          viewBox="0 0 17 19"
          class="absolute inset-y-0 right-0 h-full w-10 px-3"
        >
          <path
            stroke="#374650"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.263"
            d="M11.105 3.184V1.5m0 1.684v1.684m0-1.684h-3.79M1 8.237v7.579A1.684 1.684 0 0 0 2.684 17.5h11.79a1.684 1.684 0 0 0 1.684-1.684v-7.58M1 8.237h15.158M1 8.237V4.869a1.684 1.684 0 0 1 1.684-1.684h1.684m11.79 5.053V4.868a1.684 1.684 0 0 0-1.684-1.684h-.421M4.368 1.5v3.368"
          />
        </svg>
      </button>
      <div
        ref="calendarPopup"
        :class="`absolute ${
          align === undefined || align === 'left' ? 'left-0' : 'right-0'
        } z-20 mt-4`"
        v-if="showCalendar"
      >
        <VDatePicker
          v-model="selectedDate"
          mode="dateTime"
          is24hr
          :rules="rules"
          locale="sv"
          :disabled-dates="disabledDates"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </div>
      <div
        @click="showCalendar = false"
        class="z-2 fixed inset-0"
        v-if="showCalendar"
      ></div>
    </div>
  </div>
</template>
