import { createApp } from 'vue'
import pinia from './store/store'
import * as Sentry from '@sentry/vue'
import './assets/styles/fonts.css'
import './assets/styles/global.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'

import App from './App.vue'
import router from './router'
import { i18n } from './i18n'

const app = createApp(App)

/* if (
  import.meta.env.VITE_APP_ENV &&
  import.meta.env.VITE_APP_ENV !== 'development'
) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    autoSessionTracking: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'trucktrust.duvadev.se', /^\//]
      })
    ],
    tracesSampleRate: 0.1
  })
} */

app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VCalendar)

app.mount('#app')
