<template>
  <svg
    aria-label="TruckTrust"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
  >
    <path
      fill="#00E1A5"
      d="M35.721 0H6.241A6.242 6.242 0 0 0 0 6.24v29.52A6.242 6.242 0 0 0 6.24 42h29.52A6.242 6.242 0 0 0 42 35.76V6.24C41.962 2.796 39.167 0 35.721 0Z"
    />
    <path
      fill="#374650"
      d="M5.781 15.162H18.99a4.804 4.804 0 0 0-4.824 4.824v12.749h5.59V9.572h-8.385c-3.1 0-5.59 2.527-5.59 5.59ZM22.168 9.572v23.163h5.59V20.024a4.828 4.828 0 0 0-4.824-4.824H36.142a5.548 5.548 0 0 0-5.551-5.628h-8.423ZM8.576 32.774a2.795 2.795 0 1 0 0-5.59 2.795 2.795 0 0 0 0 5.59ZM33.386 32.774a2.795 2.795 0 1 0 0-5.59 2.795 2.795 0 0 0 0 5.59Z"
    />
  </svg>
</template>
