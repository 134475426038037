<script lang="ts" setup>
import { useLocationStore } from '@/store/location'
import Spinner from '@/components/Icon/Spinner.vue'
const locationStore = useLocationStore()

defineProps<{
  count: string
  disabled?: boolean
  checked?: boolean
}>()
</script>

<template>
  <span
    v-if="!locationStore.isFetching"
    :class="`${disabled && 'opacity-50', checked && 'rounded-sm border border-tt-gray'} min-w-6 h-6 px-[6.5px] py-0.5 text-center`"
  >
    {{ count }}
  </span>
  <span
    class="min-w-6 flex h-6 items-center justify-center px-[6.5px] py-0.5"
    v-else
  >
    <Spinner class="h-3 w-3" />
  </span>
</template>
