import { ref, watch, markRaw, type Component } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import axios from '@/axios'

import _ from 'lodash'

import { i18n } from '@/i18n'
const { t } = i18n.global

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/fuel'
})

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

type FuelPrice = {
    dataset: string
    type: string
    price: string
}

type FuelPriceAverage = {
    type: string
    avg: string
}


export const useFuelStore = defineStore('fuel', () => {
  const prices = ref<FuelPrice[]>([])
  const averages = ref<FuelPriceAverage[]>([])

  instance.get('/').then((response) => {
    prices.value = response.data.fuel_prices
    averages.value = response.data.averages
  })

  return { prices, averages }
})
