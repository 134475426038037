import type { URLOpenListenerEvent } from '@capacitor/app'

import { App as CapacitorApp } from '@capacitor/app'
import { isNative } from '@/helpers'
import {
  addListeners,
  registerNotifications,
  getDeliveredNotifications
} from '@/native/notifications'
import { useRouter } from 'vue-router'
const router = useRouter()

export default async function init() {
  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp()
    } else {
      router.go(-1)
    }
  })

  CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    // Example url: https://trucktrust.duvadev.se/shared-location/%7B%22test%22%3A%22haha%22%7D
    const split = event.url.split('/')
    try {
      // Get the last part of the url and decode it
      const data = JSON.parse(decodeURIComponent(split.pop() as string)) // Note: will probably change to a different encoding for the payload
      const path = split.pop() as string

      if (data && path) {
        console.log('Opening url', path, data)

        // TODO: Do some more stuff here like adding the location payload to the state so it can be used in the map, unless the route handles this itself

        router.push({ name: path, params: { data } })
      }
    } catch (error) {
      console.error(error) // TODO: Better error handling, like showing a toast or something
    }
  })

  if (isNative) {
    // Notifications
    await registerNotifications()
    await getDeliveredNotifications()
    await addListeners()
  }
}
