import { defineStore } from 'pinia'

export const useSidebarStore = defineStore({
  id: 'sidebar',
  state: () => ({
    open: true
  }),
  getters: {
    getSidebar: (state) => state.open
  },
  actions: {
    toggleSidebar() {
      this.open = !this.open
    },
    closeSidebar() {
      this.open = false
    },
    openSidebar() {
      this.open = true
    }
  }
})
