import type { Token } from '@/store'

import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios'
import { parseGRPCError } from '@/helpers'
import { store } from '@/storehandler/tokens'

export default class TTAxios {
  static create(config?: CreateAxiosDefaults): AxiosInstance {
    const instance = axios.create({
      withCredentials: true,
      ...config
    })

    // Setup default response interceptor
    instance.interceptors.response.use(
      async (response) => {
        const token = response.headers['tt-token']
        const refreshToken = response.headers['tt-refresh-token']
        if (token) {
          let [tok, exp] = token.split(':')
          // localstoragestore
          store.set('tt-token', {
            token: tok,
            expires: new Date(exp * 1000)
          } as Token)
        }
        // Note: Refresh token is currently not used
        if (refreshToken) {
          let [tok, exp] = token.split(':')
          store.set('tt-refresh-token', {
            token: tok,
            expires: new Date(exp * 1000)
          } as Token)
        }
        return response
      },
      (error) => {
        let err = {} as Error
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            // redirect to login page
            window.location.href = '/login'
          } else {
            err = new Error(
              parseGRPCError(error.response?.data?.error || '').desc
            )
          }
        } else if (error instanceof Error) {
          err = error
        }

        return Promise.reject(err)
      }
    )

    // Setup default request interceptor
    instance.interceptors.request.use(
      (config) => {
        const token = store.get('tt-token')
        const refreshToken = store.get('tt-refresh-token')
        const now = new Date()
        if (token && token?.token && token.expires > now) {
          config.headers['tt-token'] = token.token
        }
        if (refreshToken && refreshToken?.token && refreshToken.expires > now) {
          config.headers['tt-refresh-token'] = refreshToken.token
        }
        return config
      },
      (error) => {
        let err = {} as Error
        if (axios.isAxiosError(error)) {
          err = new Error(
            parseGRPCError(error.response?.data?.error || '').desc
          )
        } else if (error instanceof Error) {
          err = error
        }

        return Promise.reject(err)
      }
    )

    return instance
  }

  static isAxiosError = axios.isAxiosError
}
