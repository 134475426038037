import type mapboxgl from 'mapbox-gl'
import type { App, Component } from 'vue'
import { createApp } from 'vue'

import Marker from '@/ttmap/components/LocationMarker.vue'

const instances = {} as { [key: string]: App<Element>[] }

// colors to use for the categories
export const colors = ['#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c']

// Function to create a donut chart SVG
export function createTTCluster(props: any) {
  // console.log(props)
  let total = props.point_count_abbreviated

  const el = document.createElement('div')
  el.innerHTML = `<div class="h-6 w-6 flex justify-center items-center bg-tt-gray text-white text-base rounded-[4px]"> ${total.toLocaleString()} </div>`
  return el.firstChild
}

export function createTTMarker(props: any) {
  const el = document.createElement('div')
  el.className = 'relative p-2'
  const app = createApp(Marker, props)
  app.mount(el)
  return el
}

export class TTControl {
  _map: mapboxgl.Map | undefined
  _container: HTMLElement | undefined
  onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map

    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'
    this._container.id = 'tt-map-controls'

    return this._container
  }
  onRemove(map: mapboxgl.Map): void {
    this._container?.parentNode?.removeChild(this._container)
  }
}

export function attachTTComponent(
  component: Component,
  target: string,
  props: any
) {
  removeTTComponent(target)
  const app = createApp(component, props)
  app.mount(`#${target}`)
  addTTComponent(target, app)
}

function addTTComponent(target: string, app: App<Element>) {
  if (!instances[target]) {
    instances[target] = []
  }
  instances[target].push(app)
}

export function removeTTComponent(target: string) {
  if (!instances[target]) return
  for (const app of instances[target]) {
    app.unmount()
  }
}
