import { Capacitor } from '@capacitor/core'

export const parseGRPCError = (
  error: string
): { code: string; desc: string } => {
  try {
    const regex = /code = (.*) desc = (.*)/
    const match = error.match(regex)
    if (match) {
      return {
        code: match[1],
        desc: match[2]
      }
    }
  } catch (error) {
    console.warn("Couldn't parse GRPC error", error)
  }

  return {
    code: 'UNKNOWN',
    desc: 'Unknown error'
  }
}

// Make numbers human readable, e.g. 1000 -> 1K, 1000000 -> 1M and limit them to 4 characters max (including decimal)
export const humanReadableNumber = (num: number): string => {
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(0)}B`
  }
  if (num >= 1e8) {
    return `${(num / 1e6).toFixed(0)}M`
  }
  if (num >= 1e6) {
    return `${(num / 1e6).toFixed(1)}M`
  }
  if (num >= 1e5) {
    return `${(num / 1e3).toFixed(0)}K`
  }
  if (num >= 1e4) {
    return `${(num / 1e3).toFixed(1)}K`
  }
  if (num >= 1e3) {
    return `${(num / 1e3).toFixed(2)}K`
  }
  return num.toString()
}

export const humanReadableTime = (
  seconds: number
): {
  hours: number
  minutes: number
} => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return { hours, minutes }
}

export const isNative = Capacitor.isNativePlatform()
