<script lang="ts" setup>
import { ref } from 'vue'

const emit = defineEmits(['update'])
const props = defineProps<{
  active: boolean
}>()

const active = ref(props.active)

const toggle = () => {
  active.value = !active.value
  emit('update', active.value)
}
</script>
<template>
  <button @click="toggle">
    <svg xmlns="http://www.w3.org/2000/svg" :class="`h-4 w-4 ${active ? 'text-tt-green' : 'tt-light-gray'}`" fill="none">
      <path
        :fill="active ? 'currentColor' : 'none'"
        stroke="currentColor"
        d="m5.721 4.629-.26.038-4.058.592a.39.39 0 0 0-.215.663l2.936 2.861.188.184-.044.26-.695 4.04a.388.388 0 0 0 .562.41h.002l3.63-1.909.233-.122.233.122 3.63 1.909a.389.389 0 0 0 .564-.41l-.695-4.04-.044-.26.188-.184 2.936-2.86a.39.39 0 0 0-.214-.664l-4.06-.592-.26-.038-.115-.236L8.349.716a.39.39 0 0 0-.698 0l-1.93 3.913Zm0 0 .116-.236L7.651.716l-1.93 3.913Z"
      />
    </svg>
  </button>
</template>
