import { ref, watch, markRaw, type Component } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import axios from '@/axios'
import MetaFilter, { type FilterRange } from '@/filters/meta'
import type {
  DBFilterOption,
  DBFilterValue,
  FilterOption,
  FilterValue
} from '@/filters/meta'
import type { Asset } from '@/location'
import { useLocationStore } from '@/store/location'

import TtItemToggle from '@/components/TtItemToggle.vue'
import TtSlider from '@/components/TtSlider.vue'
import TtItemToggleAccordion from '@/components/TtItemToggleAccordion.vue'

import _ from 'lodash'

import { i18n } from '@/i18n'
const { t } = i18n.global

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/ui'
})

const validComponents = new Map(
  Object.entries({
    TtItemToggle: markRaw(TtItemToggle),
    TtSlider: markRaw(TtSlider),
    TtItemToggleAccordion: markRaw(TtItemToggleAccordion)
  })
) as Map<string, Component>

const getFilterValue = (filter: DBFilterValue): FilterValue => {
  if (!filter) return {} as FilterValue
  const v = {
    field: filter.field,
    key: filter.key
  } as FilterValue

  switch (filter.type) {
    case 'bool':
      v.value = filter.value === 'true'
      break
    case 'text':
      v.value = filter.value
      break
    case 'number':
      if (filter.value) v.value = +filter.value
      break
    case 'range':
      v.value = filter.range as FilterRange
      break
  }

  return v
}

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

export const useMetaFilterStore = defineStore('metafilter', () => {
  const locationStore = useLocationStore()
  const { aggregation } = storeToRefs(locationStore)

  const activeIcons = ref<Map<string, Asset[][]>>(new Map())
  const filter = ref<MetaFilter>()
  const total = ref(0)

  watch(
    () => aggregation.value,
    (agg) => {
      total.value = agg.total
      filter.value?.setAggregations(agg.aggregates)
      activeIcons.value = filter.value?.getIcons() ?? new Map()
    },
    { immediate: true, deep: true }
  )

  return {
    total,
    filter,
    activeIcons,
    buildFilter(): void {
      instance
        .get('/metafilter')
        .then((response) => {
          const { sections } = response?.data

          // iterate over each section and translate the labels
          const filters = sections.map(
            (section: { label: string; options: unknown[] }) => {
              const { label, options } = section
              const translatedLabel = t(label) // TODO: Translate this in the backend instead
              const translatedOptions = {} as { [key: string]: FilterOption }

              const convertFilterOption = (
                option: DBFilterOption
              ): FilterOption => {
                const translatedOption = t(option.label) // TODO: Translate this in the backend instead
                const translatedChildren = {} as {
                  [key: string]: FilterOption
                }

                // Convert children if they exist
                for (const child of (option?.children ??
                  []) as DBFilterOption[]) {
                  translatedChildren[child.key] = convertFilterOption(child)
                }

                // Convert the option
                return {
                  ...option,
                  label: translatedOption,
                  filter: getFilterValue(option.filter),
                  component: validComponents.get(option.component),
                  props: {
                    number: 0,
                    ...option.props
                  },
                  children: translatedChildren
                } as FilterOption
              }

              for (const option of options as DBFilterOption[]) {
                translatedOptions[option.key] = convertFilterOption(option)
              }

              return {
                ...section,
                label: translatedLabel,
                options: translatedOptions
              }
            }
          )

          filter.value = new MetaFilter(filters)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
})
