<template>
  <svg
    aria-label="TruckTrust"
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="22"
    viewBox="0 0 117 22"
    fill="none"
  >
    <path
      d="M46.5411 7.95154V13.7955C46.5411 14.3512 46.5794 15.0793 47.1543 15.6158C47.5183 15.9223 48.0931 16.1331 48.6296 16.1331C49.1853 16.1331 49.7601 15.9223 50.105 15.6158C50.6798 15.0984 50.7181 14.3512 50.7181 13.7955V7.95154H53.6497V14.2745C53.6497 15.2134 53.5347 16.4397 52.3851 17.4552C51.4271 18.2983 49.8942 18.624 48.6296 18.624C47.365 18.624 45.8322 18.2983 44.8742 17.4552C43.7245 16.4588 43.6096 15.2134 43.6096 14.2745V7.95154H46.5411Z"
      fill="#374650"
    />
    <path
      d="M63.1341 11.0939C62.5209 10.5191 61.7545 10.1934 60.739 10.1934C58.8038 10.1934 57.8266 11.6113 57.8266 13.1058C57.8266 14.8111 59.0912 16.0373 60.8348 16.0373C61.4862 16.0373 62.3485 15.8457 63.1341 15.1368V17.9726C62.5592 18.26 61.6587 18.624 60.4516 18.624C58.8229 18.624 57.3859 18.0109 56.4662 17.1103C55.6806 16.3631 54.8184 15.0793 54.8184 13.1441C54.8184 11.3238 55.5656 9.86764 56.6195 8.94793C57.8841 7.81746 59.3403 7.60669 60.3558 7.60669C61.4288 7.60669 62.3101 7.81746 63.1532 8.21983V11.0939H63.1341Z"
      fill="#374650"
    />
    <path
      d="M67.4454 3.46802V11.7262L71.3158 7.95159H75.0904L70.2428 12.4927L75.4737 18.2983H71.6032L67.7519 13.8722L67.4454 14.1596V18.2983H64.533V3.46802H67.4454Z"
      fill="#374650"
    />
    <path
      d="M106.763 10.4041C106.073 9.75267 105.383 9.71435 105.058 9.71435C104.368 9.71435 103.927 10.0401 103.927 10.5574C103.927 10.8257 104.042 11.228 104.866 11.4963L105.556 11.7071C106.361 11.9753 107.587 12.3777 108.181 13.1824C108.487 13.604 108.698 14.2363 108.698 14.9069C108.698 15.8457 108.392 16.7654 107.548 17.5319C106.705 18.2983 105.709 18.624 104.444 18.624C102.298 18.624 101.072 17.5894 100.44 16.8996L101.973 15.1368C102.548 15.8074 103.41 16.3248 104.272 16.3248C105.077 16.3248 105.709 15.9224 105.709 15.1943C105.709 14.5428 105.192 14.2937 104.808 14.1404L104.138 13.9105C103.391 13.6423 102.528 13.3165 101.915 12.6651C101.436 12.1669 101.13 11.5154 101.13 10.6915C101.13 9.69519 101.609 8.85213 102.203 8.35395C103.007 7.70249 104.061 7.60669 104.904 7.60669C105.671 7.60669 106.878 7.70249 108.2 8.69884L106.763 10.4041Z"
      fill="#374650"
    />
    <path
      d="M112.684 10.6724V18.2983H109.771V10.4425V7.95159V3.46802H112.684V7.95159H113.68C115.194 7.95159 116.401 9.17787 116.401 10.6724H112.684Z"
      fill="#374650"
    />
    <path
      d="M92.8904 7.95154V13.7955C92.8904 14.3512 92.9288 15.0793 93.5036 15.6158C93.8676 15.9223 94.4424 16.1331 94.9789 16.1331C95.5346 16.1331 96.1094 15.9223 96.4543 15.6158C97.0291 15.0984 97.0674 14.3512 97.0674 13.7955V7.95154H100.018V14.2745C100.018 15.2134 99.9032 16.4397 98.7536 17.4552C97.7955 18.2983 96.2627 18.624 94.9981 18.624C93.7335 18.624 92.2007 18.2983 91.2426 17.4552C90.093 16.4588 89.978 15.2134 89.978 14.2745V7.95154H92.8904Z"
      fill="#374650"
    />
    <path
      d="M33.9142 6.1505V18.2983H30.8102V8.64138C30.8102 7.26181 31.9215 6.1505 33.3011 6.1505H26.0201C26.0201 4.67513 27.2272 3.46802 28.7026 3.46802H36.0219C37.4973 3.46802 38.7044 4.67513 38.7044 6.1505H33.9142Z"
      fill="#374650"
    />
    <path
      d="M72.4653 6.1505C72.4653 4.67513 73.6724 3.46802 75.1478 3.46802H82.4671C83.9425 3.46802 85.1496 4.67513 85.1496 6.1505H77.8686C79.2481 6.1505 80.3595 7.26181 80.3595 8.64138V18.2983H77.2554V6.1505H72.4653Z"
      fill="#374650"
    />
    <path
      d="M41.0228 10.864C41.8693 10.864 42.5556 10.1778 42.5556 9.33119C42.5556 8.48462 41.8693 7.79834 41.0228 7.79834C40.1762 7.79834 39.4899 8.48462 39.4899 9.33119C39.4899 10.1778 40.1762 10.864 41.0228 10.864Z"
      fill="#374650"
    />
    <path
      d="M38.6852 13.4889V9.67599V7.95154H35.7728V18.2983H38.6852V13.4889Z"
      fill="#374650"
    />
    <path
      d="M87.4682 10.864C88.3147 10.864 89.001 10.1778 89.001 9.33119C89.001 8.48462 88.3147 7.79834 87.4682 7.79834C86.6216 7.79834 85.9353 8.48462 85.9353 9.33119C85.9353 10.1778 86.6216 10.864 87.4682 10.864Z"
      fill="#374650"
    />
    <path
      d="M85.1304 13.4889V9.67599V7.95154H82.218V18.2983H85.1304V13.4889Z"
      fill="#374650"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8768 0H3.12317C1.39872 0 0 1.39872 0 3.12317V17.896C0 19.6204 1.39872 21.0192 3.12317 21.0192H17.896C19.6204 21.0192 21.0192 19.6204 21.0192 17.896V3.12317C21 1.39872 19.6013 0 17.8768 0ZM2.89319 7.58758H6.70615H6.89775H9.50359C8.16235 7.58758 7.08936 8.66058 7.08936 10.0018V16.3823H9.8868V7.58758V7.45346V4.79014H5.69063C4.13863 4.79014 2.89319 6.05474 2.89319 7.58758ZM11.094 7.45347V4.79015H15.3093C16.8613 4.79015 18.1067 6.05475 18.0876 7.60675H14.083H11.4772C12.7993 7.60675 13.8914 8.67975 13.8914 10.021V16.3823H11.094V7.58759V7.45347ZM4.29203 16.4015C5.06453 16.4015 5.69076 15.7752 5.69076 15.0027C5.69076 14.2302 5.06453 13.604 4.29203 13.604C3.51954 13.604 2.89331 14.2302 2.89331 15.0027C2.89331 15.7752 3.51954 16.4015 4.29203 16.4015ZM18.1069 15.0027C18.1069 15.7752 17.4806 16.4015 16.7081 16.4015C15.9357 16.4015 15.3094 15.7752 15.3094 15.0027C15.3094 14.2302 15.9357 13.604 16.7081 13.604C17.4806 13.604 18.1069 14.2302 18.1069 15.0027Z"
      fill="#374650"
    />
  </svg>
</template>
