<script lang="ts" setup>
import { ref, computed } from 'vue'
import { humanReadableNumber } from '@/helpers'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import TtFilterCount from '@/components/TtFilterCount.vue'

const props = defineProps<{
  number?: number
  inactive?: boolean
  modelValue?: boolean
}>()

const value = ref(false)

const checked = computed({
  get: () => props.modelValue ?? value.value,
  set: (v) => {
    value.value = v
    emit('change', v)
    emit('update:modelValue', v)
  }
})

const count = computed(() => {
  if (props.number === undefined) return ''
  return humanReadableNumber(props.number)
})

const emit = defineEmits<{
  (event: 'change', value: boolean): void
  (event: 'update:modelValue', value: boolean): void
}>()
</script>

<template>
  <Disclosure :defaultOpen="false" v-slot="{ open, close }">
    <DisclosureButton as="button" class="relative flex w-full items-center">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center"
      >
        <svg
          :class="`${checked ? 'text-tt-gray' : 'rotate-180 text-white'}`"
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <slot name="accordionButton">
        <label
          class="group -mx-3 flex flex-1 cursor-pointer items-center justify-between overflow-hidden py-3.5 pl-[31px] pr-3"
        >
          <input v-model="checked" class="peer sr-only" type="checkbox" />

          <div
            class="relative z-10 flex flex-1 items-center justify-between font-light peer-checked:text-tt-gray"
          >
            <div class="flex-1 text-left">
              <slot />
            </div>
            <div class="flex w-[24%] justify-between">
              <div class="mr-2 flex h-6 w-6 items-center justify-center">
                <slot name="icon" />
              </div>
              <!-- <div
                v-if="number !== undefined"
                class="relative flex items-center justify-center font-medium border border-white/20 transition-colors rounded-sm"
              >
                <TtFilterCount :count="count" :disabled="inactive" :checked="checked" />
              </div> -->
            </div>
          </div>
          <div
            aria-hidden="true"
            class="pointer-events-none absolute inset-0 z-0 -mx-3 rounded transition-all group-hover:ring-1 group-hover:ring-tt-pale/25 peer-checked:bg-tt-pale"
          ></div>
        </label>
      </slot>
    </DisclosureButton>
    <div v-show="checked" class="pl-[19px]">
      <DisclosurePanel static>
        <slot name="items"></slot>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>
