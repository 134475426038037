<template>
  <svg
    aria-hidden="true"
    aria-label="Trusted Trucker"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0565 0H1.93163C0.865087 0 0 0.863709 0 1.92856V11.0507C0 12.1156 0.865087 12.9793 1.93163 12.9793H11.0684C12.1349 12.9793 13 12.1156 13 11.0507V1.92856C12.9881 0.863709 12.1231 0 11.0565 0Z"
      fill="#374650"
    />
    <path
      d="M1.78931 4.68518H4.14756H4.26606H5.87773C5.04819 4.68518 4.38457 5.34775 4.38457 6.17597V10.1159H6.11474V4.68518V4.60236V2.95776H3.51948C2.55959 2.95776 1.78931 3.73865 1.78931 4.68518Z"
      fill="white"
    />
    <path
      d="M6.86121 2.95776V4.60236V4.68518V10.1159H8.59138V6.1878C8.59138 5.35958 7.9159 4.69701 7.09822 4.69701H8.70988H11.1866C11.1985 3.73865 10.4282 2.95776 9.46832 2.95776H6.86121Z"
      fill="white"
    />
    <path
      d="M2.65439 10.1278C3.13217 10.1278 3.51948 9.74111 3.51948 9.2641C3.51948 8.78709 3.13217 8.40039 2.65439 8.40039C2.17662 8.40039 1.78931 8.78709 1.78931 9.2641C1.78931 9.74111 2.17662 10.1278 2.65439 10.1278Z"
      fill="white"
    />
    <path
      d="M10.3335 10.1278C10.8112 10.1278 11.1986 9.74111 11.1986 9.2641C11.1986 8.78709 10.8112 8.40039 10.3335 8.40039C9.8557 8.40039 9.46838 8.78709 9.46838 9.2641C9.46838 9.74111 9.8557 10.1278 10.3335 10.1278Z"
      fill="white"
    />
  </svg>
</template>
