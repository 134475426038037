<script setup lang="ts">
import type { Token } from '@/store'
import type { Position } from '@capacitor/geolocation'
import { onMounted, watch, ref } from 'vue'
import { useMapsStore } from '@/store/maps'
import { useLocationStore } from '@/store/location'
import { storeToRefs } from 'pinia'
import { store } from '@/storehandler/tokens'
import { SplashScreen } from '@capacitor/splash-screen'
import TTMap from '@/ttmap'

import userPosition from '@/native/geolocation'

const mapStore = useMapsStore()
const {
  geometries,
  route,
  directions,
  currentRouteAlternative,
  currentLocation,
  mapStyle
} = storeToRefs(mapStore)
const { lastId, pin } = storeToRefs(useLocationStore())

let map: TTMap
onMounted(async () => {
  // mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
  map = new TTMap(import.meta.env.VITE_MAPBOX_ACCESS_TOKEN, {
    container: 'map',
    style: 'mapbox://styles/mapbox/' + mapStyle.value,
    center: userPosition.position.value
      ? [
          userPosition.position.value.coords.longitude,
          userPosition.position.value.coords.latitude
        ]
      : undefined,
    zoom: userPosition.position.value ? 12 : -1,
    maxPitch: 0,
    touchPitch: false,
    logoPosition: 'bottom-left',
    transformRequest: (url: string) => {
      // if the url matches 'VITE_GATEWAY_URL' then add the token to the headers
      const token = store.get('tt-token') as Token
      if (!token || !url.startsWith(import.meta.env.VITE_GATEWAY_URL)) {
        return { url }
      }

      const headers = {
        'tt-token': token?.token
      }
      return {
        url,
        headers
      }
    }
  })

  pin.value = map.pinMarker

  map.on('load', () => {
    SplashScreen.hide() // Hide splash screen when map is loaded
    userPosition.toggleMotion(true) // Start tracking motion
    map.save()
    map.renderRoute()
  })
})

watch(
  () => [
    geometries.value,
    route.value,
    directions.value,
    currentRouteAlternative.value
  ],
  () => {
    map.renderRoute()

    // if directions is null, disable the location watcher
    userPosition.toggleWatcher(!directions.value)
  }
)
watch(
  () => mapStyle.value,
  () => {
    map.setStyle('mapbox://styles/mapbox/' + mapStyle.value)
  }
)

// watch for geolocation permissions
const hasSnapped = ref(false)
watch(
  () =>
    [userPosition.position.value, userPosition.rotation.value] as [
      Position | undefined,
      number
    ],
  ([position, rotation]) => {
    if (!position) return
    map.updateCurrentLocationmarker(
      {
        lng: position.coords.longitude,
        lat: position.coords.latitude
      },
      rotation
    )

    // If the user has already snapped to their location, don't do it again
    if (hasSnapped.value) return
    hasSnapped.value = true
    map.flyTo({
      center: [position.coords.longitude, position.coords.latitude],
      zoom: 12
    })
  }
)

watch(
  () => currentLocation.value,
  () => {
    //map.updateCurrentLocationmarker(currentLocation.value)
    map.flyTo({
      center: currentLocation.value,
      zoom: 12
    })
  }
)

// TODO: Remove this later and implement a more robust solution for adding the newly created location to the map, (This is just for testing)
watch(
  () => lastId.value,
  () => {
    map.locations.refresh()
  }
)
</script>

<template>
  <div
    class="position fixed top-0 z-20 h-safe-top w-screen backdrop-blur"
  ></div>
  <div id="map" class="h-screen w-screen bg-tt-light-gray"></div>
</template>
