<template>
  <svg
    width="6"
    height="11"
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.87489 10.5627C4.69898 10.5627 4.52298 10.4956 4.38892 10.3613L0.263916 6.2363C-0.00463866 5.96774 -0.00463866 5.53268 0.263916 5.26413L4.38892 1.13913C4.65747 0.870575 5.09253 0.870575 5.36108 1.13913C5.62964 1.40768 5.62964 1.84274 5.36108 2.1113L1.72206 5.75021L5.36151 9.38967C5.63007 9.65822 5.63007 10.0933 5.36151 10.3618C5.22724 10.4961 5.05106 10.5627 4.87489 10.5627Z"
      fill="currentColor"
    />
  </svg>
</template>
