import { PushNotifications } from '@capacitor/push-notifications'
import { useAccountStore } from '@/store/account'

const { setFirebaseID } = useAccountStore()

const addListeners = async () => {
  if (!(await checkPermissions)) return

  await PushNotifications.addListener('registration', (token) => {
    setFirebaseID(token.value)
    console.info('Registration token: ', token.value)
  })

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error)
  })

  await PushNotifications.addListener(
    'pushNotificationReceived',
    (notification) => {
      console.log('Push notification received: ', notification)
    }
  )

  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification) => {
      console.log(
        'Push notification action performed',
        notification.actionId,
        notification.inputValue
      )
    }
  )
}

const checkPermissions = async () => {
  let permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!')
  }

  return permStatus.receive === 'granted'
}

const registerNotifications = async () => {
  if (!(await checkPermissions)) return
  await PushNotifications.register()
}

const getDeliveredNotifications = async () => {
  if (!(await checkPermissions)) return
  const notificationList = await PushNotifications.getDeliveredNotifications()
  console.log('delivered notifications', notificationList)
}

export { addListeners, registerNotifications, getDeliveredNotifications }
