<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import Location from '@/components/Location.vue'
import { useAccountStore } from './store/account'
import { storeToRefs } from 'pinia'
import capacitorInit from '@/native/capacitor'
import { useMetaFilterStore } from './store/metafilter'
import { useMapsStore } from '@/store/maps'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
import SidebarSection from '@/components/Sidebar/SidebarSection.vue'
import TtNotification from '@/components/TtNotification.vue'
import Reset from '@/components/Icon/Reset.vue'
import TtButton from '@/components/TtButton.vue'
import LocationFilter from '@/components/LocationFilter.vue'
import { SplashScreen } from '@capacitor/splash-screen'
import { useI18n } from 'vue-i18n'
import Debugger from '@/components/Debugger.vue'
import { useDebugStore } from '@/store/debug'

const routerRoute = useRoute()

const { t } = useI18n({ useScope: 'global' })

const debug = useDebugStore()
const { filter } = storeToRefs(useMetaFilterStore())
const { route } = storeToRefs(useMapsStore())
const hasActiveFilter = computed(() => {
  if (!filter.value) return false
  return filter.value.sections.some((section) => {
    return Object.values(section.options).some((option) => option.active)
  })
})

// import { CapacitorCookies } from '@capacitor/core'
const { isLoggedIn } = storeToRefs(useAccountStore())
const { buildFilter } = useMetaFilterStore()
// App hooks etc.
capacitorInit()
watch(
  isLoggedIn,
  () => {
    if (isLoggedIn.value) {
      buildFilter()
    } else {
      // Hide splash if user is not logged in
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 1000
        })
      }, 1000)
    }
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div>
    <main>
      <TtNotification>
        <Debugger v-if="debug.debug" />
        <RouterView />
        <Suspense v-if="isLoggedIn">
          <Sidebar
            v-if="
              !routerRoute.fullPath.includes('location') &&
              !routerRoute.fullPath.includes('route')
            "
          >
            <template #primarySidebarButton>
              <button v-if="hasActiveFilter">
                <Reset @click="filter?.resetAll()" />
              </button>
            </template>
            <SidebarSection :noDefaultMargin="true" class="mt-8">
              <h2 class="text-lg font-semibold leading-9 tracking-tight">
                {{ t('route.route') }}
              </h2>
              <div class="mt-3 pb-3">
                <TtButton
                  :as="'router-link'"
                  :to="route ? '/route' : '/route/new'"
                  :size="'large'"
                  :type="'primary'"
                >
                  <template v-if="!route">{{
                    t('route.createNewRoute')
                  }}</template>
                  <template v-else>{{ t('route.continueOnRoute') }}</template>
                </TtButton>
              </div>
            </SidebarSection>
            <LocationFilter />
          </Sidebar>
        </Suspense>
        <Suspense v-if="isLoggedIn">
          <Location />
        </Suspense>
      </TtNotification>
    </main>
  </div>
</template>
