<script setup lang="ts">
import type TTMap from '@/ttmap'
import _ from 'lodash'
import TtSquareButton from '@/components/TtSquareButton.vue'
import { useMapsStore } from '@/store/maps'
import { storeToRefs } from 'pinia'

const { mapStyle } = storeToRefs(useMapsStore())

const zoom = (zoom: number) => {
  if (!prop.map) return
  if (zoom > 0) prop.map?.zoomIn()
  else prop.map?.zoomOut()
}

const fullscreen = () => {
  // TODO: implement fullscreen
  console.log('Not implemented: Toggle fullscreen')
}

const prop = defineProps<{
  map?: TTMap
}>()

const changeMapStyle = (style: 'streets-v11' | 'satellite-streets-v12') => {
  if (!prop.map) return
  mapStyle.value = style
}
</script>

<template>
  <div
    class="mb-[calc(env(safe-area-inset-bottom)+1rem)] mr-1 flex flex-col items-end"
  >
    <TtSquareButton
      type="tertiary"
      as="button"
      class="mb-2"
      @click.prevent="zoom(1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8 1v14M1 8h14"
        />
      </svg>
    </TtSquareButton>
    <TtSquareButton
      type="tertiary"
      as="button"
      class="mb-2"
      @click.prevent="zoom(-1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="2"
        fill="none"
        viewBox="0 0 16 2"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M1 1h14"
        />
      </svg>
    </TtSquareButton>
    <TtSquareButton
      type="tertiary"
      as="button"
      :class="`mb-2 ${mapStyle === 'satellite-streets-v12' && 'opacity-50'}`"
      @click.prevent="
        mapStyle === 'streets-v11'
          ? changeMapStyle('satellite-streets-v12')
          : changeMapStyle('streets-v11')
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="12"
        fill="none"
        viewBox="0 0 22 12"
      >
        <path fill="#fff" d="M5.5 4 0 11.5h21.5L13 0 9 4.5l3 4-2 1.5-4.5-6Z" />
      </svg>
    </TtSquareButton>
    <!-- <TtSquareButton
      type="tertiary"
      as="button"
      class="mb-2"
      @click.prevent="fullscreen"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 1H3a2 2 0 0 0-2 2v3m18 0V3a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M1 14v3a2 2 0 0 0 2 2h3"
        />
      </svg>
    </TtSquareButton> -->
  </div>
</template>
