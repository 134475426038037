import { createI18n } from 'vue-i18n'
import enUS from './languages/en_US.json'
import svSE from './languages/sv_SE.json'

const i18n = createI18n({
    legacy: false,
    locale: 'sv-SE',
    supportedLocales: ['en-US', 'sv-SE'],
    globalInjection: true,
    messages: {
        'en-US': enUS,
        'sv-SE': svSE
    }
})

export { i18n }