<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 19"
  >
    <path
      stroke="#374650"
      stroke-width="1.5"
      d="M17 18H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2Z"
    />
    <path
      stroke="#374650"
      stroke-width="1.5"
      d="M16 5V3.603a2 2 0 0 0-2.515-1.932l-11 2.933A2 2 0 0 0 1 6.537V7"
    />
    <path
      fill="#374650"
      d="M4 9.207h5.214A1.896 1.896 0 0 0 7.31 11.11v5.032h2.206V7h-3.31A2.21 2.21 0 0 0 4 9.207ZM10.469 7v9.143h2.206v-5.017a1.906 1.906 0 0 0-1.904-1.904h5.214A2.19 2.19 0 0 0 13.793 7H10.47ZM5.103 16.159a1.103 1.103 0 1 0 0-2.207 1.103 1.103 0 0 0 0 2.207ZM14.897 16.158a1.103 1.103 0 1 0 0-2.206 1.103 1.103 0 0 0 0 2.206Z"
    />
  </svg>
</template>
