<template>
  <svg
    class="animate-spin"
    height="1rem"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <path
      fill="currentColor"
      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM230.7 367.5L156.4 272H48.61C56.17 371.4 133.7 451.3 232 462.6V369C231.6 368.6 231.1 368 230.7 367.5H230.7zM280 369V462.6C378.3 451.3 455.8 371.4 463.4 272H355.6L281.3 367.5C280.9 368 280.4 368.6 280 369H280zM352 224H461.6C446.2 124.3 359.1 48 256 48C152 48 65.84 124.3 50.45 224H160L167.2 209.7C172.6 198.8 183.7 192 195.8 192H316.2C328.3 192 339.4 198.8 344.8 209.7L352 224z"
    />
  </svg>
</template>
