import StoreHandler from '@/storehandler'

export const store = new StoreHandler(
  (value) => {
    return JSON.stringify(value)
  },
  (value) => {
    const t = JSON.parse(value)
    t.expires = new Date(t.expires)
    return t
  }
)
