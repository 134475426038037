<script lang="ts" setup>
import { computed, useSlots } from 'vue'

const props = withDefaults(
  defineProps<{
    variant:
      | 'overlay-dark'
      | 'overlay-light'
      | 'popup-card'
      | 'popup-card-offset'
      | 'popup-card-fixed'
      | 'popup-card-bottom'
    open: boolean
  }>(),
  {}
)

const slots = useSlots()
const emits = defineEmits(['setOpen'])

const open = computed({
  get() {
    return props.open
  },
  set(value: boolean) {
    emits('setOpen', value)
  }
})

const setOpen = (value: boolean) => {
  open.value = value
}

const hasOffsetContent = computed(() => !!slots.offset)
</script>

<template>
  <div
    :class="`pointer-events-none fixed inset-0 z-30 transition-all ${
      variant !== 'overlay-light' &&
      variant !== 'overlay-dark' &&
      'sm:flex sm:items-center sm:justify-center'
    }
    ${
      (variant === 'overlay-light' || variant === 'overlay-dark') &&
      !open &&
      'translate-y-[calc(100%-3.875rem-env(safe-area-inset-bottom))] sm:translate-y-[calc(100%-5.75rem-env(safe-area-inset-bottom))]'
    }
    ${
      (variant === 'popup-card' ||
        variant === 'popup-card-offset' ||
        variant === 'popup-card-fixed' ||
        variant === 'popup-card-bottom') &&
      !open &&
      'opacity-0'
    }`"
  >
    <div
      v-if="variant !== 'overlay-light' && variant !== 'overlay-dark'"
      :class="`${
        open ? 'opacity-1 pointer-events-auto' : 'pointer-events-none opacity-0'
      } fixed inset-0 cursor-pointer bg-[rgba(33,41,47,0.83)] backdrop-blur-md transition-opacity`"
      @click="setOpen(false)"
    ></div>
    <div
      :class="`absolute top-0 mt-[calc(2rem+env(safe-area-inset-top))] flex w-full justify-center ${
        open ? 'pointer-events-auto' : 'pointer-events-none'
      }`"
      v-if="variant === 'popup-card-offset'"
    >
      <slot name="offset" />
    </div>
    <div
      :style="`--offset-top: ${hasOffsetContent ? '10rem' : '5.375rem'};`"
      :class="[
        'fixed z-20 shadow-sidebar transition-all',
        {
          'inset-0 flex flex-col overflow-hidden rounded-t-md bg-[rgba(33,41,47,0.83)] pb-safe-bottom text-white backdrop-blur-md duration-300 sm:left-6 sm:mt-[calc(env(safe-area-inset-top)+32px)] sm:w-[390px] sm:pb-0 sm:pt-0':
            variant === 'overlay-dark',
          'inset-0 flex flex-col overflow-hidden rounded-t-md bg-white pb-safe-bottom text-tt-gray duration-300 sm:left-6 sm:mt-[calc(env(safe-area-inset-top)+32px)] sm:w-[390px] sm:pb-0 sm:pt-0':
            variant === 'overlay-light',
          'inset-x-3 inset-y-3 flex h-[calc(100vh-1.5rem)] flex-col overflow-hidden rounded-3xl bg-white text-tt-gray duration-300 sm:static sm:h-auto sm:max-h-[calc(100vh-1.5rem)] sm:w-[450px] sm:items-center sm:justify-center':
            variant === 'popup-card',
          'inset-x-3 bottom-0 flex max-h-[calc(100vh-0.75rem)] flex-col overflow-hidden rounded-t-3xl bg-white pb-safe-bottom text-tt-gray duration-300 sm:static sm:w-[450px] sm:items-center sm:justify-center sm:rounded-3xl':
            variant === 'popup-card-fixed',
          'inset-x-3 bottom-[calc(0.75rem+env(safe-area-inset-bottom))] top-[calc(var(--offset-top)+env(safe-area-inset-top))] flex flex-col rounded-3xl bg-white text-tt-gray duration-300 sm:static sm:max-h-[calc(100vh-(var(--offset-top)*2+env(safe-area-inset-top)))] sm:w-[450px] sm:items-center sm:justify-center':
            variant === 'popup-card-offset',
          'inset-x-0 bottom-0 flex flex-col rounded-t-3xl bg-white pb-safe-bottom text-tt-gray duration-300 sm:static sm:max-h-[calc(100vh-(var(--offset-top)*2+env(safe-area-inset-top)))] sm:w-[450px] sm:items-center sm:justify-center sm:rounded-3xl':
            variant === 'popup-card-bottom'
        },
        `${
          (variant === 'popup-card' ||
            variant === 'popup-card-offset' ||
            variant === 'popup-card-fixed' ||
            variant === 'popup-card-bottom') &&
          !open
            ? 'pointer-events-none'
            : 'pointer-events-auto'
        }`
      ]"
    >
      <div
        v-if="
          variant === 'popup-card-offset' ||
          variant === 'popup-card' ||
          variant === 'popup-card-fixed' ||
          variant === 'popup-card-bottom'
        "
        class="relative w-full"
      >
        <slot name="header" />
      </div>
      <div
        :class="`h-full w-full flex-1 ${open ? 'overflow-auto' : 'overflow-hidden'}`"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
