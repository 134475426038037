<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="a" x1="80.815%" x2="59.868%" y1="59.637%" y2="8.15%">
        <stop offset="0%" stop-color="#EF2131" />
        <stop offset="100%" stop-color="#FECF2C" />
      </linearGradient>
      <linearGradient id="b" x1="58.818%" x2="9.244%" y1="0%" y2="94.071%">
        <stop offset="0%" stop-color="#FBC52C" />
        <stop offset="26.392%" stop-color="#F87130" />
        <stop offset="56.08%" stop-color="#EF52E2" />
        <stop offset="100%" stop-color="#661EEC" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="20.569%"
        x2="40.597%"
        y1="41.018%"
        y2="91.277%"
      >
        <stop offset="0%" stop-color="#78F6D8" />
        <stop offset="26.571%" stop-color="#77D1F6" />
        <stop offset="55.447%" stop-color="#70A4F3" />
        <stop offset="100%" stop-color="#661EEC" />
      </linearGradient>
      <linearGradient id="d" x1="42.44%" x2="91.614%" y1="100%" y2="6.278%">
        <stop offset="0%" stop-color="#536EED" />
        <stop offset="24.666%" stop-color="#54C3EC" />
        <stop offset="56.42%" stop-color="#64D769" />
        <stop offset="100%" stop-color="#FECF2C" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="nonzero">
      <path
        fill="url(#a)"
        d="M6.826 22.831c4.82 2.306 10.771 1.165 14.366-3.118 4.26-5.076 3.598-12.645-1.479-16.904l-3.375 4.022a9.372 9.372 0 0 1 1.155 13.206c-2.654 3.162-7.024 4.218-10.667 2.794"
      />
      <path
        fill="url(#b)"
        d="M6.826 22.831c4.82 2.306 10.771 1.165 14.366-3.118.44-.524.827-1.076 1.163-1.647a10.491 10.491 0 0 0-3.617-9.72 10.509 10.509 0 0 0-2.4-1.515 9.372 9.372 0 0 1 1.155 13.206c-2.654 3.162-7.024 4.218-10.667 2.794"
      />
      <path
        fill="url(#c)"
        d="M17.174 1.169C12.354-1.137 6.404.004 2.808 4.287-1.452 9.363-.79 16.932 4.286 21.19l3.376-4.022A9.372 9.372 0 0 1 6.507 3.963C9.16.801 13.53-.255 17.174 1.17"
      />
      <path
        fill="url(#d)"
        d="M17.174 1.169C12.354-1.137 6.404.004 2.808 4.287c-.44.524-.827 1.076-1.163 1.647a10.491 10.491 0 0 0 3.617 9.72 10.51 10.51 0 0 0 2.399 1.515A9.372 9.372 0 0 1 6.507 3.963C9.16.801 13.53-.255 17.174 1.17"
      />
    </g>
  </svg>
</template>
