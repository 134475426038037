import type { PluginListenerHandle } from '@capacitor/core'
import { Motion } from '@capacitor/motion'
import { isNative } from '@/helpers'

// let accelHandler: PluginListenerHandle
const motion = {
  start: async (callback: (event: any) => void) => {
    const handlers = {} as {
      [key: string]: PluginListenerHandle
    }

    // IOS 13+ requires permission
    const isIOS =
      isNative &&
      typeof (DeviceMotionEvent as any).requestPermission === 'function'
    console.log('isIOS', isIOS)
    try {
      if (isIOS) {
        await Promise.all([
          (DeviceMotionEvent as any).requestPermission((state: any) => {
            console.log('DeviceMotionEvent permission state: ', state)
          }),
          (DeviceOrientationEvent as any)
            .requestPermission()
            .then((state: any) => {
              console.log('DeviceOrientationEvent permission state: ', state)
            })
        ])
      }
    } catch (e) {
      // Handle error
      console.error("Couldn't get permission for DeviceMotionEvent", e)
      return null
    }

    // Once the user approves, can start listening:
    try {
      // For IOS, we use the Capacitor Motion plugin
      if (isIOS) {
        handlers['accel'] = await Motion.addListener('accel', callback)
        handlers['orientation'] = await Motion.addListener(
          'orientation',
          callback
        )
      } else {
        // For Android, we use the native events directly
        window.addEventListener('devicemotion', callback)
        window.addEventListener('deviceorientationabsolute', callback)
      }
    } catch (e) {
      // Handle error
      console.error(e)
      return null
    }

    return handlers
  },
  stop: (handler?: any) => {
    if (handler) {
      switch (typeof handler) {
        case 'function':
          window.removeEventListener('devicemotion', handler)
          window.removeEventListener('deviceorientationabsolute', handler)
          break
        case 'object':
          handler.remove()
          break
      }
    }
  }
}

export default motion
