<script lang="ts" setup>
import type { TTListOption } from '@/types'

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { computed, ref, useSlots, watch } from 'vue'

const emit = defineEmits(['update'])

const props = defineProps<{
  options: TTListOption[]
  defaultValue?: unknown
  optionDisplay: string | string[]
  dark?: boolean
  locationFeature?: boolean
  roundLeft?: boolean
}>()

const slots = useSlots()

const selected = ref(props.defaultValue || null)

watch(selected, (val) => {
  emit('update', val)
})

const hasLabel = computed(() => !!slots.label)
</script>

<template>
  <Listbox as="div" v-model="selected">
    <ListboxButton
      :class="`form-input relative flex w-full items-center border bg-transparent text-base font-light leading-4.5 transition-colors placeholder:text-white focus:border-tt-green focus:outline-none focus:ring-0 disabled:opacity-50 ${
        dark && locationFeature
          ? 'border-black/10 pl-1 pr-2.5 text-tt-gray'
          : dark
          ? 'border-tt-gray/25 px-3 text-tt-gray'
          : 'border-tt-pale/25 px-3 text-white'
      } ${hasLabel ? 'pb-2 pt-6' : 'py-4'} ${roundLeft ? 'rounded-l' : 'rounded'}`"
    >
      <label
        :class="`pointer-events-none absolute top-0 z-0 origin-left scale-75 font-medium ${
          dark ? 'text-tt-gray/50' : 'text-white/50'
        } `"
      >
        <slot name="label" />
      </label>
      <div class="mr-5 flex-1 pl-2 text-left"><slot /></div>
      <div class="absolute inset-y-0 right-2.5 flex flex-shrink-0 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="6"
          fill="none"
        >
          <path
            fill="currentColor"
            d="M10.313 1.063a.685.685 0 0 1-.202.486L5.986 5.674a.687.687 0 0 1-.972 0L.889 1.549A.687.687 0 1 1 1.86.576L5.5 4.216 9.14.575a.687.687 0 0 1 1.172.487Z"
          />
        </svg>
      </div>
    </ListboxButton>

    <ListboxOptions
      :class="`absolute z-20 mt-3 max-h-40 w-full overflow-auto rounded shadow-sidebar outline-none ${
        dark ? 'bg-white' : 'bg-black'
      }`"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="`${
          index < options.length - 1
            ? `border-b ${dark ? 'border-black/10' : 'border-tt-gray'}`
            : ''
        }`"
      >
        <ListboxOption as="template" v-slot="{ active }" :value="option">
          <button
            :class="`flex w-full cursor-pointer items-center justify-between gap-3 rounded px-3 py-3 text-left text-base ${
              active && 'ring-1 ring-inset ring-tt-green'
            } ${dark ? 'text-tt-gray' : 'text-white'}`"
          >
            <slot name="option" :option="option">
              <span>
                {{
                  typeof optionDisplay === 'string'
                    ? option[optionDisplay]
                    : optionDisplay
                        .map((i) => {
                          return option[i]
                        })
                        .join(' ')
                }}
              </span>
            </slot>
            <span v-if="active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="8"
                fill="none"
              >
                <path
                  fill="currentColor"
                  d="M9.101.47a.47.47 0 1 1 .941 0v3.296c0 .953-.772 1.725-1.726 1.725H1.607l1.706 1.707a.47.47 0 0 1-.665.665L.139 5.353a.469.469 0 0 1 0-.665l2.51-2.512a.47.47 0 0 1 .665.665L1.607 4.548h6.71a.784.784 0 0 0 .784-.784V.47Z"
                />
              </svg>
            </span>
          </button>
        </ListboxOption>
      </div>
    </ListboxOptions>
  </Listbox>
</template>
