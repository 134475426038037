<script setup lang="ts">
import type { Asset } from '@/location'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import Icon from '@/components/Icon.vue'

defineProps<{
  icons?: Asset[][] // 2D array of icons
  closed?: boolean
}>()

const emit = defineEmits(['toggle'])
</script>

<template>
  <Disclosure :defaultOpen="closed ? false : true" v-slot="{ open }">
    <div class="flex items-center justify-between">
      <DisclosureButton
        as="button"
        class="flex flex-1 items-center justify-start gap-2"
        @click="emit('toggle', !open)"
      >
        <div>
          <svg
            :class="!open && 'rotate-180'"
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <slot name="accordionButton"></slot>
        <div v-if="!open && icons?.length" class="flex items-center -space-x-2">
          <Icon
            v-for="assets in icons?.slice(0, 5)"
            :assets="assets"
            :priority="['icon-map']"
            class="flex-shrink-0 rounded-full border-2 border-tt-gray bg-tt-gray"
          />
          <div
            v-if="icons?.length && icons?.length > 5"
            class="flex h-6 w-6 items-center justify-center rounded-full border-2 border-tt-gray bg-white text-sm font-semibold text-tt-gray"
          >
            +{{ icons?.length - 5 }}
          </div>
        </div>
      </DisclosureButton>
      <slot name="accordionAction"></slot>
    </div>
    <div v-show="open">
      <DisclosurePanel static>
        <slot></slot>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>
