<script lang="ts" setup>
import { useSidebarStore } from '@/store/sidebars'
import { storeToRefs } from 'pinia'

import Quicksearch from '@/components/Sidebar/Quicksearch.vue'
import LogoIconDark from '@/components/Logo/LogoIconDark.vue'
import LogoFull from '@/components/Logo/LogoFull.vue'
import Toggle from '@/components/Icon/Toggle.vue'
import ChevronLeft from '@/components/Icon/ChevronLeft.vue'

const { toggleSidebar } = useSidebarStore()
const { open } = storeToRefs(useSidebarStore())
</script>

<template>
  <div :class="`${open ? 'px-5 pt-3.5' : 'px-3.5 pt-2.5'}`">
    <div :class="`flex items-center ${open && 'gap-4'}`">
      <button v-if="!open" @click="toggleSidebar" class="mr-3 flex-shrink-0">
        <LogoIconDark class="h-7 w-7" />
      </button>
      <Quicksearch :class="`${!open && 'mr-3.5'}`" />
      <button @click="toggleSidebar">
        <Toggle />
      </button>
    </div>
  </div>
  <div class="mt-9 px-5">
    <div :class="`flex items-center justify-between ${!open && 'opacity-0'}`">
      <router-link
        to="/"
        class="flex flex-1 items-center justify-between gap-3"
      >
        <LogoFull class="h-7 w-auto" />
      </router-link>
      <div>
        <slot name="primarySidebarButton" />
        <template
          v-if="
            $route.fullPath.includes('location') ||
            $route.fullPath.includes('route')
          "
        >
          <RouterLink
            to="/"
            class="flex h-7 w-7 items-center justify-center rounded-[3px] border border-white/20 text-white"
            ><span class="sr-only">Gå till start</span><ChevronLeft />
          </RouterLink>
        </template>
      </div>
    </div>
  </div>
</template>
