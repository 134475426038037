<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20281 0.494431L5.38892 4.17221L1.33058 4.76388C0.602805 4.86944 0.311138 5.76666 0.838916 6.28055L3.77503 9.14166L3.08058 13.1833C2.95558 13.9139 3.72503 14.4611 4.36947 14.1194L8.00003 12.2111L11.6306 14.1194C12.275 14.4583 13.0445 13.9139 12.9195 13.1833L12.225 9.14166L15.1612 6.28055C15.6889 5.76666 15.3973 4.86944 14.6695 4.76388L10.6111 4.17221L8.79726 0.494431C8.47226 -0.161125 7.53059 -0.169459 7.20281 0.494431Z"
      fill="#00E1A5"
    />
  </svg>
</template>
