<script lang="ts" setup>
import type { FilterEvent, FilterRange } from '@/filters/meta'
import { FilterEventType } from '@/filters/meta'

import { useLocationStore } from '@/store/location'
import { computed, ref } from 'vue'
import Slider from '@vueform/slider'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { humanReadableNumber } from '@/helpers'
import TtFilterCount from '@/components/TtFilterCount.vue'
import IconArrow from '@/components/Icon/Arrow.vue'

const props = defineProps<{
  modelValue?: number
  tooltips?: boolean
  show_value?: boolean
  min?: number
  max?: number
  default?: number
  show_min?: boolean
  show_max?: boolean
  prefix?: string
  min_prefix?: string
  max_prefix?: string
  suffix?: string
  afterSliderIcon?: any
  inactive?: boolean
  number?: number
  type?: string // over | under
}>()

const { updateAggregations } = useLocationStore()

const emit = defineEmits<{
  (event: 'change', value: FilterEvent): void
  (event: 'update:modelValue', value: number): void
}>()

const value = ref(props.default ?? 0)

const classes = ref({
  target:
    'relative box-border select-none touch-none tap-highlight-transparent w-full touch-callout-none disabled:cursor-not-allowed',
  focused: 'slider-focused',
  tooltipFocus: 'slider-tooltip-focus',
  tooltipDrag: 'slider-tooltip-drag',
  ltr: 'slider-ltr',
  rtl: 'slider-rtl',
  horizontal: 'slider-horizontal h-px',
  vertical: 'slider-vertical w-1.5 h-80',
  textDirectionRtl: 'slider-txt-rtl',
  textDirectionLtr: 'slider-txt-ltr',
  base: 'w-full h-full relative z-1 bg-white/25 rounded',
  connects: 'w-full h-full relative overflow-hidden z-0 rounded',
  connect:
    'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-transparent cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed',
  origin:
    'slider-origin absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full h:h-0 v:-top-full txt-rtl-h:left-0 txt-rtl-h:right-auto v:w-0 tap:duration-300 tap:transition-transform',
  handle:
    'absolute rounded-full bg-tt-green border-0 cursor-grab focus:outline-none h:w-4 h:h-4 h:-top-2 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-4 v:h-4 v:-top-2 v:-right-1.25 disabled:cursor-not-allowed focus:bg-tt-green',
  handleLower: 'slider-hande-lower',
  handleUpper: 'slider-hande-upper',
  touchArea: 'h-full w-full',
  tooltip:
    'absolute block text-sm font-semibold whitespace-nowrap py-1 px-1.5 min-w-5 text-center text-tt-gray rounded border border-tt-pale bg-tt-pale transform h:-translate-x-1/2 h:left-1/2 v:-translate-y-1/2 v:top-1/2 disabled:bg-gray-400 disabled:border-gray-400 merge-h:translate-x-1/2 merge-h:left-auto merge-v:-translate-x-4 merge-v:top-auto tt-focus:hidden tt-focused:block tt-drag:hidden tt-dragging:block',
  tooltipTop: 'bottom-6 h:arrow-bottom merge-h:bottom-3.5',
  tooltipBottom: 'top-6 h:arrow-top merge-h:top-5',
  tooltipLeft: 'right-6 v:arrow-right merge-v:right-1',
  tooltipRight: 'left-6 v:arrow-left merge-v:left-7',
  tooltipHidden: 'slider-tooltip-hidden',
  active: 'slider-active shadow-slider-active cursor-grabbing',
  draggable: 'cursor-ew-resize v:cursor-ns-resize',
  tap: 'slider-state-tap',
  drag: 'slider-state-drag'
})

const count = computed(() => {
  if (props.number === undefined) return ''
  return humanReadableNumber(props.number)
})

const triggerChange = (close: boolean) => {
  if (close) {
    emit('change', {
      type: FilterEventType.Slider,
      value: false
    } as FilterEvent)
  } else {
    const valueType = ['over', 'under']?.includes(props.type || '')
      ? props.type
      : 'over'

    emit('change', {
      type: FilterEventType.Slider,
      value: {
        min: valueType === 'over' ? value.value : undefined,
        max: valueType === 'under' ? value.value : undefined
      } as FilterRange
    } as FilterEvent)
    emit('update:modelValue', value.value)
  }
}

const onSliderChange = () => {
  triggerChange(false)
  updateAggregations()
}
</script>

<template>
  <Disclosure as="div" v-slot="{ open }">
    <DisclosureButton
      as="button"
      :class="`relative -ml-3 flex w-[calc(100%+1.5rem)] cursor-pointer items-center justify-between overflow-hidden rounded px-3 py-3.25 transition-shadow hover:ring-1 hover:ring-tt-pale/25 ${
        open && 'bg-tt-pale text-tt-gray'
      }`"
      @click="triggerChange(open)"
    >
      <div
        class="relative z-10 flex flex-1 items-center justify-evenly font-light"
      >
        <div class="flex flex-1 items-center justify-start gap-2 text-left">
          <div>
            <svg
              :class="`${!open && 'rotate-180'}`"
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <slot />
        </div>
        <div class="flex items-center gap-4">
          <!-- TODO : Show average price right now just a demo -->
          <div v-if="suffix === '€/kWh'" class="font-mono">
            <span class="inline-block font-sans text-tt-red">
              <IconArrow />
            </span>
            0.7
            <span :class="`${open ? 'text-tt-gray/60' : 'text-white/60'}`">{{
              suffix
            }}</span>
          </div>
          <div class="flex w-[22%] justify-between">
            <div
              class="mr-[13px] flex w-6 flex-shrink-0 items-center justify-center"
            >
              <slot name="icon" />
            </div>
            <div
              v-if="number !== undefined"
              class="relative flex items-center justify-center rounded-sm border border-white/20 font-medium transition-colors"
            >
              <TtFilterCount
                :count="count"
                :disabled="inactive"
                :checked="open"
              />
            </div>
          </div>
        </div>
      </div>
    </DisclosureButton>
    <div v-show="open">
      <DisclosurePanel static>
        <div
          class="-mx-3 mt-1 overflow-hidden rounded bg-black py-3.5 pl-3 pr-[19px]"
        >
          <div class="flex h-6 items-center space-x-4">
            <div
              v-if="show_value ? show_value : false"
              class="flex-shrink-0 font-mono"
            >
              <span v-if="prefix">{{ prefix }}</span> {{ value || 0 }}
              <span class="text-white/60">{{ suffix }}</span>
            </div>
            <Slider
              v-model="value"
              :tooltips="false"
              :showTooltip="'focus'"
              :min="min ? min : 0"
              :max="max ? max : 100"
              :lazy="false"
              :classes="classes"
              @change="onSliderChange"
            />
          </div>
        </div>
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>
