<template>
  <svg
    class="h-4 w-4"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m15.753 14.719-4.188-4.188c.907-1.11 1.407-2.506 1.407-4.031a6.5 6.5 0 0 0-6.5-6.5C2.882 0 0 2.91 0 6.5S2.91 13 6.472 13a6.462 6.462 0 0 0 4.03-1.408l4.188 4.188c.175.148.37.22.56.22a.75.75 0 0 0 .53-.22.73.73 0 0 0-.027-1.061ZM1.5 6.5c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5-5-2.244-5-5Z"
      fill="currentColor"
    />
  </svg>
</template>
