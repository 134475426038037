<script lang="ts" setup></script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C5.78333 16 3.89583 15.2208 2.3375 13.6625C0.779167 12.1042 0 10.2167 0 8C0 5.78333 0.779167 3.89583 2.3375 2.3375C3.89583 0.779167 5.78333 0 8 0C9.41667 0 10.6583 0.2875 11.725 0.8625C12.7917 1.4375 13.7167 2.225 14.5 3.225V0H16V6.35H9.65V4.85H13.85C13.2167 3.85 12.4083 3.04167 11.425 2.425C10.4417 1.80833 9.3 1.5 8 1.5C6.18333 1.5 4.64583 2.12917 3.3875 3.3875C2.12917 4.64583 1.5 6.18333 1.5 8C1.5 9.81667 2.12917 11.3542 3.3875 12.6125C4.64583 13.8708 6.18333 14.5 8 14.5C9.38333 14.5 10.65 14.1042 11.8 13.3125C12.95 12.5208 13.75 11.475 14.2 10.175H15.75C15.2667 11.925 14.3083 13.3333 12.875 14.4C11.4417 15.4667 9.81667 16 8 16Z"
      fill="currentColor"
    />
  </svg>
</template>
