<template>
  <svg aria-label="TruckTrust" viewBox="0 0 608 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#logofullclippath)">
      <path
        d="M93.3 0h-77C7.3 0 0 7.3 0 16.3v77.1c0 9 7.3 16.3 16.3 16.3h77.1c9 0 16.3-7.3 16.3-16.3V16.3c-.1-9-7.4-16.3-16.4-16.3Z"
        fill="#00E1A5"
      />
      <path
        d="M242.9 41.5V72c0 2.9.2 6.7 3.2 9.5 1.9 1.6 4.9 2.7 7.7 2.7 2.9 0 5.9-1.1 7.7-2.7 3-2.7 3.2-6.6 3.2-9.5V41.5H280v33c0 4.9-.6 11.3-6.6 16.6-5 4.4-13 6.1-19.6 6.1-6.6 0-14.6-1.7-19.6-6.1-6-5.2-6.6-11.7-6.6-16.6v-33h15.3ZM329.5 57.9c-3.2-3-7.2-4.7-12.5-4.7-10.1 0-15.2 7.4-15.2 15.2 0 8.9 6.6 15.3 15.7 15.3 3.4 0 7.9-1 12-4.7v14.8c-3 1.5-7.7 3.4-14 3.4-8.5 0-16-3.2-20.8-7.9-4.1-3.9-8.6-10.6-8.6-20.7 0-9.5 3.9-17.1 9.4-21.9 6.6-5.9 14.2-7 19.5-7 5.6 0 10.2 1.1 14.6 3.2v15h-.1ZM352 18.1v43.1l20.2-19.7h19.7l-25.3 23.7 27.3 30.3h-20.2l-20.1-23.1-1.6 1.5v21.6h-15.2V18.1H352ZM557.2 54.3c-3.6-3.4-7.2-3.6-8.9-3.6-3.6 0-5.9 1.7-5.9 4.4 0 1.4.6 3.5 4.9 4.9l3.6 1.1c4.2 1.4 10.6 3.5 13.7 7.7 1.6 2.2 2.7 5.5 2.7 9 0 4.9-1.6 9.7-6 13.7s-9.6 5.7-16.2 5.7c-11.2 0-17.6-5.4-20.9-9l8-9.2c3 3.5 7.5 6.2 12 6.2 4.2 0 7.5-2.1 7.5-5.9 0-3.4-2.7-4.7-4.7-5.5l-3.5-1.2c-3.9-1.4-8.4-3.1-11.6-6.5-2.5-2.6-4.1-6-4.1-10.3 0-5.2 2.5-9.6 5.6-12.2 4.2-3.4 9.7-3.9 14.1-3.9 4 0 10.3.5 17.2 5.7l-7.5 8.9ZM588.1 55.7v39.8h-15.2V18.1h15.2v23.4h5.2c7.9 0 14.2 6.4 14.2 14.2h-19.4ZM484.8 41.5V72c0 2.9.2 6.7 3.2 9.5 1.9 1.6 4.9 2.7 7.7 2.7 2.9 0 5.9-1.1 7.7-2.7 3-2.7 3.2-6.6 3.2-9.5V41.5H522v33c0 4.9-.6 11.3-6.6 16.6-5 4.4-13 6.1-19.6 6.1-6.6 0-14.6-1.7-19.6-6.1-6-5.2-6.6-11.7-6.6-16.6v-33h15.2ZM177 32.1v63.4h-16.2V45.1c0-7.2 5.8-13 13-13h-38c0-7.7 6.3-14 14-14H188c7.7 0 14 6.3 14 14h-25Z"
        fill="#E6FFF5"
      />
      <path
        d="M378.2 32.1c0-7.7 6.3-14 14-14h38.2c7.7 0 14 6.3 14 14h-38c7.2 0 13 5.8 13 13v50.4h-16.2V32.1h-25ZM214.1 56.7a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM201.9 70.4V41.5h-15.2v54h15.2V70.4ZM456.5 56.7a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
        fill="#E6FFF5"
      />
      <path d="M444.3 70.4V41.5h-15.2v54h15.2V70.4Z" fill="#E6FFF5" />
      <path
        d="M15.1 39.6h34.5c-7 0-12.6 5.6-12.6 12.6v33.3h14.6V25H29.7c-8.1 0-14.6 6.6-14.6 14.6ZM57.9 25v60.5h14.6V52.3c0-7-5.7-12.6-12.6-12.6h34.5C94.5 31.6 88 25 79.9 25h-22ZM22.4 85.6a7.3 7.3 0 1 0 0-14.6 7.3 7.3 0 0 0 0 14.6ZM87.2 85.6a7.3 7.3 0 1 0 0-14.6 7.3 7.3 0 0 0 0 14.6Z"
        fill="#374650"
      />
    </g>
    <defs>
      <clipPath id="logofullclippath"><path fill="#fff" d="M0 0h607.5v109.6H0z" /></clipPath>
    </defs>
  </svg>
</template>
