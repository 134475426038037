<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M5.25 3C5.25 2.17157 5.92157 1.5 6.75 1.5H11.25C12.0785 1.5 12.75 2.17157 12.75 3V4.5H14.2423C14.2469 4.49995 14.2516 4.49995 14.2562 4.5H15.75C16.1642 4.5 16.5 4.83579 16.5 5.25C16.5 5.66421 16.1642 6 15.75 6H14.9483L14.2979 15.1069C14.2418 15.8918 13.5886 16.5 12.8017 16.5H5.19834C4.41138 16.5 3.75822 15.8918 3.70215 15.1069L3.05166 6H2.25C1.83579 6 1.5 5.66421 1.5 5.25C1.5 4.83579 1.83579 4.5 2.25 4.5H3.74381C3.74846 4.49995 3.75311 4.49995 3.75774 4.5H5.25V3ZM6.75 4.5H11.25V3H6.75V4.5ZM4.55548 6L5.19834 15H12.8017L13.4445 6H4.55548Z"
      fill="currentColor"
    />
  </svg>
</template>
