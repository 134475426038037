<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import TtButton from '@/components/TtButton.vue'
import IconParking from '@/components/Icon/Parking.vue'
import ProfilePicture from './Icon/ProfilePicture.vue'
import type { Location } from '@/location'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { store } from '@/storehandler/tokens'
import { useRouter } from 'vue-router'
import { useLocationStore } from '@/store/location'
import {
  differenceInCalendarDays,
  isSameDay,
  isAfter,
  isBefore
} from 'date-fns'

const { t } = useI18n({ useScope: 'global' })
const router = useRouter()

const props = defineProps<{ location: Location; hideGoTo?: boolean }>()
const emit = defineEmits(['hasFetched'])

const isLoading = ref(true)

const bookings = ref<
  {
    id: number
    userId: number
    vehicle: string
    from: string
    to: string
    parkingSpot: string
    price: number
    currency: string
  }[]
>([])

const currencySymbol = (currency: string) => {
  const formatter = new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency
  })

  let symbol
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value
    }
  })

  return symbol
}
const now = new Date()

const parsedDate = (from: string, to: string) => {
  //TODO dictionary
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]

  const fromDate = new Date(from)
  const toDate = new Date(to)
  let formattedFromTo = ''

  const daysUntilFrom = differenceInCalendarDays(fromDate, now)
  const daysUntilTo = differenceInCalendarDays(toDate, now)
  if (isSameDay(fromDate, toDate)) {
    if (daysUntilFrom === 0) {
      formattedFromTo = t('dates.today')
    } else if (daysUntilFrom === 1) {
      formattedFromTo = t('dates.tomorrow')
    } else if (daysUntilFrom < 7) {
      formattedFromTo = t('dates.inXdays', { count: daysUntilFrom })
    }
  } else {
    if (daysUntilFrom < 0) {
      if (daysUntilFrom === -1) {
        formattedFromTo = t('dates.yesterday')
      } else {
        formattedFromTo =
          fromDate.getDate() + ' ' + t('dates.' + months[fromDate.getMonth()])
      }
    } else if (daysUntilFrom === 0) {
      formattedFromTo = t('dates.today')
    } else if (daysUntilFrom === 1) {
      formattedFromTo = t('dates.tomorrow')
    }
    if (formattedFromTo !== '') {
      formattedFromTo += ' ' + t('dates.to') + ' '
      if (daysUntilTo === 0) {
        formattedFromTo += t('dates.today')
      } else if (daysUntilTo === 1) {
        formattedFromTo += t('dates.tomorrow')
      } else {
        formattedFromTo +=
          toDate.getDate() + ' ' + t('dates.' + months[toDate.getMonth()])
      }
    } else {
      if (fromDate.getMonth() === toDate.getMonth()) {
        formattedFromTo =
          fromDate.getDate() +
          '-' +
          toDate.getDate() +
          ' ' +
          t('dates.' + months[fromDate.getMonth()])
      } else {
        formattedFromTo =
          fromDate.getDate() +
          ' ' +
          t('dates.' + months[fromDate.getMonth()]) +
          ' ' +
          t('dates.to') +
          ' ' +
          toDate.getDate() +
          ' ' +
          t('dates.' + months[toDate.getMonth()])
      }
    }
  }

  return formattedFromTo
}

const goToLocation = () => {
  useLocationStore().flyToLocation(props.location, 18)
  router.push({
    name: 'location',
    params: { id: props.location.id }
  })
}

onMounted(async () => {
  if (!props.location.parkings) {
    emit('hasFetched', 0)
    isLoading.value = false
    return
  }

  bookings.value = await Promise.all(
    props.location.parkings.map(async (parking) => {
      const response = await useLocationStore().fetchParkingBookings(parking.id)
      if (!response || response.error) return []
      return response.map((booking: any) => {
        return {
          id: booking.id,
          userId: booking.user_id,
          vehicle: booking.reg_number,
          from: booking.from,
          to: booking.to,
          parkingSpot: parking.name,
          price: parking.price,
          currency: parking.currency
        }
      })
    })
  ).then((results) =>
    results
      .flat()
      .filter(Boolean)
      .sort((a, b) => {
        return new Date(a.from).getTime() - new Date(b.from).getTime()
      })
  )
  emit(
    'hasFetched',
    bookings.value.filter((booking) => isAfter(new Date(booking.to), now))
      .length
  )
  isLoading.value = false
})
</script>

<template>
  <div v-if="!isLoading">
    <div class="flex flex-col gap-1">
      <template v-if="bookings.length === 0">
        <span>{{ t('locations.noBookingsYet') }}</span>
      </template>
      <template v-else>
        <div v-for="booking in bookings" :key="booking.id">
          <div
            v-if="isAfter(new Date(booking.to), now)"
            class="flex h-[52px] w-full items-center justify-between rounded border border-tt-gray/25 p-4"
          >
            <div class="flex items-center">
              <IconParking />
              <ProfilePicture
                :userId="booking.userId"
                class="mx-2 h-6 w-6 rounded-full"
              />
              <span class="mr-1">{{ booking.parkingSpot }}</span>
              <span v-if="booking.vehicle" class="mr-1"
                >({{ booking.vehicle }})</span
              >
              <span>{{ parsedDate(booking.from, booking.to) }}</span>
            </div>
            <span class="font-mono"
              >{{ booking.price }}
              <span class="text-tt-gray/50">{{
                currencySymbol(booking.currency)
              }}</span></span
            >
          </div>
        </div>
        <TtButton
          v-if="
            bookings.filter((booking) => isBefore(new Date(booking.to), now))
              .length
          "
          :as="'button'"
          :size="'large'"
          :type="'tertiary'"
          class="-mb-3 mt-6 !flex-grow px-8 tracking-tighter"
        >
          {{ t('location.viewBookingHistory') }}
        </TtButton>
      </template>
      <TtButton
        v-if="!hideGoTo"
        :as="'button'"
        :size="'large'"
        :type="'tertiary'"
        class="mt-6 !flex-grow px-8 tracking-tighter"
        @click="goToLocation"
      >
        {{ t('location.goToLocation') }}
      </TtButton>
    </div>
  </div>
  <div v-else>Laddar...</div>
</template>
