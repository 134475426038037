<script setup lang="ts">
import type { Location } from '@/location'
import type { Asset } from '@/location'

import { computed } from 'vue'

const icon = computed(() => {
  const assets = props.assets || props.location?.assets

  // Add default icon regex
  const priority = [...(props.priority || []), 'icon.*']

  let icon = null
  for (const assetType of priority) {
    const re = new RegExp(assetType)
    icon = assets?.find((asset) => re.test(asset.type))
    if (icon) break
  }

  if (!icon) {
    console.warn(`No icon found for ${props.type}`)
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' // 1x1 transparent gif
  }

  return `${import.meta.env.VITE_GATEWAY_URL.replace('/v1', '')}/assets/${
    icon.type
  }/${icon.name}${icon.ext}`
})

const props = withDefaults(
  defineProps<{
    location?: Location // Optional as you can also pass in assets directly
    assets?: Asset[] // Optional as these can also be passed in via location
    size?: number
    priority?: string[] // as regex strings
  }>(),
  {
    size: 6,
    type: []
  }
)
</script>

<template>
  <img :src="icon" :class="[`w-${props.size} h-${props.size}`]" />
</template>
