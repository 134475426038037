import type TTMap from '@/ttmap'

import Location from '@/ttmap/components/LocationPopup.vue'

import {
  createTTCluster,
  createTTMarker,
  attachTTComponent,
} from './functions'
import mapboxgl from 'mapbox-gl'
import pinia from '@/store/store'
import { storeToRefs } from 'pinia'
import { useLocationStore } from '@/store/location'
import router from '@/router'

const location = useLocationStore(pinia)
const { locationSource } = storeToRefs(location)

export default class LocationMarkers {
  map: TTMap
  markers: { [key: string]: mapboxgl.Marker }
  visibleMarkers: { [key: string]: mapboxgl.Marker }
  constructor(map: TTMap) {
    this.map = map
    this.markers = {}
    this.visibleMarkers = {}
  }

  populate() {
    if (this.map.getSource('locations')) {
      console.warn('Locations already populated')
      return
    }

    // add a clustered GeoJSON source for a sample set of locations
    this.map.addSource('locations', {
      type: 'geojson',
      data: locationSource.value,
      cluster: true,
      clusterRadius: 50,
      clusterMaxZoom: 10,
      clusterMinPoints: 2,
      clusterProperties: {}
    })
    this.map.addLayer({
      id: 'location_label',
      type: 'symbol',
      source: 'locations',
      filter: ['!=', 'cluster', true],
      layout: {},
      paint: {}
    })

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    this.map.on('render', () => {
      if (!this.map.isSourceLoaded('locations')) return
      this.update()
    })
  }

  createPopup(props: any) {
    const popup = new mapboxgl.Popup({
      offset: 25,
      maxWidth: '300px',
      closeOnMove: false
    }).setHTML('<div id="tt-location-popup"></div>')
    popup.on('open', (e) => {
      attachTTComponent(Location, 'tt-location-popup', props)
      this.map.pinMarker.active = true
      location.open = true
      this.map.flyTo(
        {
          center: props.coordinates,
          essential: true
        }
      )
    })
    return popup
  }

  refresh() {
    const source = this.map.getSource('locations') as mapboxgl.GeoJSONSource
    if (!source) return
    source.setData(locationSource.value)
  }

  update() {
    const newMarkers = {} as { [key: string]: mapboxgl.Marker }
    const features = this.map.querySourceFeatures('locations')

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      if (feature.geometry.type !== 'Point') continue
      const coords = feature.geometry.coordinates
      const props = feature.properties
      if (!props) continue

      // Parse JSON props
      for (const key in props) {
        try {
          props[key] = JSON.parse(props[key])
        } catch (e) {}
      }

      const id = props.cluster_id ? `cluster_${props.cluster_id}` : props.id
      if (!id) continue

      let marker = this.markers[id]
      if (!marker) {
        // Get marker element
        let el = props.cluster ? createTTCluster(props) : createTTMarker(props)

        // Create a new Mapbox marker
        marker = this.markers[id] = new mapboxgl.Marker({
          element: el as HTMLElement
        }).setLngLat(coords as mapboxgl.LngLatLike)

        if (!props.cluster) {
          marker = marker.setPopup(this.createPopup(props))
          marker.on('click', () => {
            if (location.open) {
              console.log('click lol')
            }
          })
        }
      }
      newMarkers[id] = marker

      if (!this.visibleMarkers[id]) marker.addTo(this.map)
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.visibleMarkers) {
      if (!newMarkers[id]) this.visibleMarkers[id].remove()
    }
    this.visibleMarkers = newMarkers
  }

  hideAll() {
    console.log('hide all')
    const markers = document.querySelectorAll('.mapboxgl-marker:not(.location)')
    markers.forEach((marker) => {
      marker.style.visibility = 'hidden'
    })
  }

  showAll() {
    console.log('show all')
    const markers = document.querySelectorAll('.mapboxgl-marker:not(.location)')
    markers.forEach((marker) => {
      marker.style.visibility = 'visible'
    })
  }
}
