<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import countries from '../data/countries.json'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'

const model = defineModel<string, string>()

const selectedCountry = ref({
  name: 'Sweden',
  code: 'SE',
  dial_code: '+46'
})

if (model.value) {
  const country = countries.find(
    (country) => country.dial_code === model.value?.split(' ')[0]
  )
  if (country) {
    selectedCountry.value = country
  }
}

const number = ref(model.value ? model.value.split(' ').slice(1).join(' ') : '')

watchEffect(() => {
  model.value = `${selectedCountry.value.dial_code} ${number.value}`
})
</script>
<template>
  <div class="mt-4">
    <div class="relative flex">
      <Listbox v-slot="{ open }" v-model="selectedCountry">
        <ListboxButton
          class="flex h-[3.25rem] flex-shrink-0 items-center gap-1.5 rounded-l border border-r-0 border-tt-light-gray pl-4 pr-3.5"
        >
          <img :src="`/flags/${selectedCountry.code}.svg`" class="h-3 w-4.5" />
          <span class="text-base font-normal tracking-tighter">{{
            selectedCountry.dial_code
          }}</span>
          <svg
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class=""
            :class="`${!open && 'rotate-180'} flex-shrink-0`"
          >
            <path
              d="M.688 4.812c0-.176.067-.352.2-.486L5.015.201a.687.687 0 0 1 .972 0l4.125 4.125a.687.687 0 1 1-.972.973L5.5 1.659 1.86 5.3a.687.687 0 0 1-1.173-.487Z"
              fill="currentColor"
            />
          </svg>
        </ListboxButton>
        <ListboxOptions
          class="absolute -top-4 max-h-40 w-full -translate-y-full overflow-auto rounded bg-white shadow-sidebar outline-none"
        >
          <div
            v-for="(country, index) in countries"
            :key="country.code"
            :class="
              index < countries.length - 1 ? `border-b border-black/10` : ''
            "
          >
            <ListboxOption as="template" v-slot="{ active }" :value="country">
              <button
                :class="`flex w-full cursor-pointer items-center gap-1.5 rounded px-3 py-3 text-left text-base text-tt-gray ${
                  active && 'ring-1 ring-inset ring-tt-green'
                }`"
              >
                <img :src="`/flags/${country.code}.svg`" class="h-3 w-4.5" />
                <span
                  class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap font-medium"
                  >{{ country.name }}</span
                >
                <span class="flex-shrink-0 font-light"
                  >({{ country.dial_code }})</span
                >
              </button>
            </ListboxOption>
          </div>
        </ListboxOptions>
      </Listbox>
      <input
        type="text"
        class="form-input w-full rounded-r border-tt-light-gray px-4 text-base font-normal tracking-tighter transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
        v-model="number"
      />
    </div>
  </div>
</template>
