<script lang="ts" setup>
import LogoIconDark from '@/components/Logo/LogoIconDark.vue'
import { storeToRefs } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useProfileStore } from '@/store/profile'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const { user } = storeToRefs(useAccountStore())
const { currentCompany, currentVehicle } = storeToRefs(useProfileStore())
</script>

<template>
  <div class="flex w-full flex-col items-center">
    <span v-if="user?.id" class="flex items-center space-x-1">
      <LogoIconDark class="block h-4 w-4" />
      <span class="text-sm font-semibold tracking-tighter">
        Trusted Trucker #{{ user?.id }}
      </span>
    </span>
    <div v-else class="mb-8 w-full text-center">
      <button
        class="mb-4 flex w-full flex-shrink-0 items-center justify-center rounded border border-tt-gray/25 py-4 text-lg font-semibold leading-4.5 tracking-[-0.43px] text-tt-gray"
      >
        <LogoIconDark class="mr-2 block h-4 w-4" />
        <span>
          {{ t('profile.becomeATrustedTrucker') }}
          <span class="font-light">(23 kr/mån)</span>
        </span>
      </button>
      <router-link to="/" class="light text-tt-blue underline">
        {{ t('profile.trustedTruckerReadMore') }}
      </router-link>
    </div>
    <h1 class="-mb-1 mt-2 text-3xl font-semibold tracking-tighter text-black">
      {{ user?.firstname }} {{ user?.lastname || '' }}
    </h1>
    <span class="font-light tracking-tight text-tt-gray">
      {{ currentCompany?.name || t('profile.noEmployer')
      }}<template
        v-if="
          currentCompany?.id && currentVehicle?.id && currentVehicle?.reg_number
        "
        >, {{ currentVehicle.reg_number }}
      </template>
    </span>
  </div>
</template>
