import { ref, type Ref } from 'vue'
import { App } from '@capacitor/app'
import { Geolocation, type Position } from '@capacitor/geolocation'
import type { PluginListenerHandle } from '@capacitor/core'
import motion from '@/native/motion'

class UserPosition {
  position: Ref<Position | undefined>
  rotation: Ref<number> = ref(0)
  options: PositionOptions

  accDistance: Ref<number> = ref(0)
  debugging: Ref<Record<string, any>> = ref({})
  private distance: {
    buffer: number
    updates: number
  } = {
    buffer: 0,
    updates: 0
  }
  private handlers: void | { [key: string]: PluginListenerHandle } | null = null
  private watch: Ref<string> = ref('')
  private distanceWatcher = setInterval(() => {
    if (this.distance.buffer == 0) return

    let averageAcceleration =
      (this.distance.buffer / this.distance.updates) * 9.81 // Average acceleration in m/s^2
    let timeInSeconds = 100 / 1000 // Convert ms to s
    let distanceTraveled =
      0.5 * averageAcceleration * Math.pow(timeInSeconds, 2) // d = 1/2at^2

    this.accDistance.value += distanceTraveled
    this.distance.buffer = 0
    this.distance.updates = 0

    // if totalDistance is greater than 10, then we can assume the user has moved a significant distance
    if (this.accDistance.value > 10) {
      this.updatePosition()
      this.accDistance.value = 0
    }
  }, 100)

  constructor(options?: PositionOptions) {
    this.position = ref<Position>()
    this.options = options || {
      enableHighAccuracy: true
    }

    // this.toggleMotion(true)
    this.updatePosition()

    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.updatePosition()
      }
    })
  }

  updatePosition = async () => {
    this.position.value = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true
    })
  }

  private handleAccel = (event: any) => {
    const { x, y, z } = event.acceleration

    this.debugging.value['acceleration'] = {
      x,
      y,
      z
    }
    // console.log('Acceleration:', x, y, z)

    this.distance.buffer += Math.sqrt(x ** 2 + y ** 2 + z ** 2)
    this.distance.updates++
  }

  // Basics, the 0 degreees is always left, 90 is always up, 180 is always right, 270 is always down
  private handleOrientation = (event: any) => {
    let { alpha, beta, gamma } = event
    if (event.webkitCompassHeading) {
      alpha = event.webkitCompassHeading
    }

    this.debugging.value['orientation'] = {
      alpha,
      beta,
      gamma
    }
    let rotation = 0

    const isFlipped = beta < -90 || beta > 90

    // if the device is flipped upside down, then we need to rotate the map by 180 degrees
    if (isFlipped) {
      // console.log('Flipped')
      rotation = alpha + 180
    } else {
      rotation = alpha
    }

    this.rotation.value = -Math.abs(rotation % 360)
    // console.log('Heading', this.position.value?.coords.heading)
    // console.log('Rotation:', this.rotation.value)
    // console.log('Orientation:', rotation % 360, beta, gamma)
  }

  private handler = (event: any) => {
    switch (event.type) {
      case 'devicemotion':
        this.handleAccel(event)
        break
      case 'deviceorientation':
      case 'deviceorientationabsolute':
        this.handleOrientation(event)
        break
    }
  }

  toggleMotion = async (enable: boolean) => {
    if (enable && !this.handlers) {
      motion.start(this.handler).then((handler) => {
        this.handlers = handler
      })
    } else if (!enable) {
      for (const key in this.handlers) {
        this.handlers[key].remove()
      }

      motion.stop(this.handler)
    }
  }

  toggleWatcher = async (enable: boolean) => {
    if (enable && !this.watch.value) {
      // console.log('Enabling location watcher')
      Geolocation.watchPosition(
        {
          enableHighAccuracy: true
        },
        (position) => {
          // console.log('User moved:', position)
          if (!position) return
          this.position.value = position
        }
      ).then((watchId) => {
        this.watch.value = watchId
      })
    } else if (!enable) {
      // console.log('Disabling location watcher')
      Geolocation.clearWatch({
        id: this.watch.value
      })
    }
  }
}

export default new UserPosition()
