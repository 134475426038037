<template>
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00002 4.41397L8.29832 5.12491L5.37147 2.19806L5.37147 9.77832L4.35585 9.77832L4.35585 2.19806L1.43823 5.12491L0.727295 4.41397L4.86366 0.27761L9.00002 4.41397Z"
      fill="currentColor"
    />
  </svg>
</template>
