import type TTMap from '@/ttmap'
import type { Location } from '@/location'
import type { Place, Directions, Geometry, Waypoint } from '@/maps'
import type { RequestError } from '@/types'
import { defineStore } from 'pinia'
import { useProfileStore } from './profile'
import { useStorage } from '@vueuse/core'
import polyline from '@/utils/geojson'
import axios from '@/axios'
import { isValidDate } from '@/utils/date'
import { Geolocation } from '@capacitor/geolocation'
import { i18n } from '@/i18n'
const { t } = i18n.global


const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/maps'
})

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

const jsonDateSerializer = {
  read: (v: string) => {
    const d = new Date(parseInt(v))
    return isValidDate(d) ? d : null
  },
  write: (v: Date) => v?.getTime().toString()
}

export const useMapsStore = defineStore({
  id: 'maps',
  state: () => ({
    map: null as TTMap | null,
    route: useStorage<Place[] | undefined>('maps.route', null, undefined, {
      serializer: jsonSerializer
    }),    
    previousRoutes: useStorage<Place[][]>(
      'maps.previousRoutes',
      [],
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    directions: useStorage<Directions[]>(
      'maps.directions',
      [],
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    geometries: useStorage<Geometry[]>('maps.geometries', [], undefined, {
      serializer: jsonSerializer
    }),
    currentRouteAlternative: 0,
    routeCreatedAt: useStorage<Date | null>(
      'maps.routeCreatedAt',
      null,
      undefined,
      {
        serializer: jsonDateSerializer
      }
    ),
    routeMarkers: [] as mapboxgl.Marker[],
    currentLocation: {} as mapboxgl.LngLatLike,
    mapStyle: useStorage<'streets-v11' | 'satellite-streets-v12'>('maps.mapStyle', 'streets-v11'),
  }),
  getters: {},
  actions: {
    async search({
      query,
      lang = 'sv',
      proximity = 'ip',
      limit = 5
    }: {
      query: string
      lang: string
      proximity: string
      limit: number
    }): Promise<RequestError | Place[]> {
      try {
        const response = await instance.get('/search', {
          params: {
            q: query,
            lang,
            proximity,
            limit
          }
        })
        return response.status == 200
          ? response.data.features
          : { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async reverse({
      coordinates,
      lang = 'sv'
    }: {
      coordinates: number[]
      lang?: string
    }) {
      try {
        const response = await instance.get(
          '/reverse/' + coordinates[0] + ',' + coordinates[1],
          {
            params: {
              lang
            }
          }
        )
        return response.status == 200
          ? response.data.features
          : { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async getDirections({
      places,
      overview = 'simplified'
    }: {
      places?: Place[]
      overview?: string
    }): Promise<RequestError | null> {
      try {
        places = places ?? this.route ?? []
        this.directions = []
        this.geometries = []
        if (!places || places.length < 2) {
          return { error: 'Not enough places' }
        }

        const coordinates = places
          .map((place) => place.center.join(','))
          .join(';')

        const params = {
          overview
        } as any
        const profileStore = useProfileStore()
        const totalHeight = profileStore.getTotalHeight
        const totalWeight = profileStore.getTotalWeight
        const totalWidth = profileStore.getTotalWidth

        if (totalHeight) params['max_height'] = totalHeight
        if (totalWeight) params['max_weight'] = totalWeight
        if (totalWidth) params['max_width'] = totalWidth

        const response = await instance.get(`/directions/${coordinates}`, { params })
        const routes = response.data.routes as Directions[]
        const legTime = new Date()
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i]
          route.waypoints = route.waypoints.map((waypoint: Waypoint, i: number) => {
            const leg = route.legs[Math.max(i - 1, 0)]
            const duration = (i > 0 ? leg.duration_typical ?? leg.duration : 0) * 1000
            legTime.setTime(legTime.getTime() + duration) // Set a new time so we account for previous legs in the route
            const estimated_arrival = new Date(legTime.getTime())
            return {
              ...waypoint,
              distance: i > 0 ? leg.distance : 0,
              estimated_arrival
            }
          })
          this.geometries.push(polyline.toGeoJSON(route.geometry))
          this.directions.push(route)
        }
       
        this.map?.renderRoute()
        return response.status == 200
          ? null
          : { error: 'Error in request', response: response }
      } catch (error: unknown) {
        console.error(error)
        if (error instanceof Error) {
          return { error: error.message as string }
        }
        return { error: 'Undefined error' }
      }
    },
    async addLocationToRoute(location: Location): Promise<void> {
      this.route = this.route ?? []
      if (this.route.length === 0) {
        const currentPosition = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        })
        const place: Place = {
          place_name: t('location.currentLocation'),
          center: [
            currentPosition.coords.longitude,
            currentPosition.coords.latitude
          ]
        }
        this.route.push(place)
      }
      const placeName =
        location.name !== ''
          ? location.name
          : location.address?.street !== ''
          ? location.address.street
          : location.coordinates.lng.toFixed(6) +
            ', ' +
            location.coordinates.lat.toFixed(6)
      const place: Place = {
        place_name: placeName,
        center: [location.coordinates.lng, location.coordinates.lat]
      }
      this.route.push(place)
      this.getDirections({ places: this.route, overview: 'full' })
    },
    saveRoute(route: Place[]): void {
      this.route = route
      this.routeCreatedAt = new Date()
    },
    clearRoute(): void {
      if (this.route) this.previousRoutes.unshift(this.route)
      this.route = undefined
      this.routeCreatedAt = null
      this.geometries = []
      this.directions = []
    },
    saveMap(map: any): void {
      this.map = map
    },
    saveMarker(marker: any): void {
      this.routeMarkers.push(marker)
    },
    resetMarkers(): void {
      this.routeMarkers.forEach((marker: mapboxgl.Marker) => marker.remove())
      this.routeMarkers = [] as mapboxgl.Marker[]
    },
    resetStore(): void {
      this.map = null
      this.route = undefined
      this.routeCreatedAt = null
      this.geometries = []
      this.directions = []
    }
  }
})
