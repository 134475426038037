<script lang="ts" setup>
import { computed } from 'vue'
import TtAccordion from '@/components/TtAccordion.vue'
import ParkingSpace from '@/components/ParkingSpace.vue'
import IconParking from '@/components/Icon/Parking.vue'
import { useLocationStore } from '@/store/location'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

const { t } = useI18n({ useScope: 'global' })
const locations = useLocationStore()
const { location } = storeToRefs(locations)

const priceRange = computed(() => {
  const prices = location.value?.parkings?.map((parking) => parking.price) ?? []
  if (Math.min(...prices) === Math.max(...prices)) return Math.min(...prices)
  return `${Math.min(...prices)}-${Math.max(...prices)}`
})
</script>

<template>
  <div
    v-if="location?.parkings?.length"
    class="mx-5 mb-5 border-b border-black/10 pb-5"
  >
    <TtAccordion>
      <template v-slot:accordionButton>
        <div class="flex w-full items-center justify-between font-light">
          <div>
            <h2 class="text-lg font-semibold leading-9 tracking-tight">
              {{ t('filter.places.parkings') }}
            </h2>
          </div>
          <div class="flex items-center gap-2 text-lg tracking-tight">
            <span>{{ priceRange }}{{ t('filter.places.cost_day') }}</span>
            <IconParking />
          </div>
        </div>
      </template>
      <div class="pt-3">
        <div class="grid gap-1.5">
          <ParkingSpace
            v-for="parking in location.parkings"
            :key="parking.id"
            :location="location"
            :parking="parking"
          />
        </div>
      </div>
    </TtAccordion>
  </div>
</template>
