<script setup lang="ts">
import type { Location } from '@/location'

import { computed } from 'vue'

import Icon from '@/components/Icon.vue'
import LogoMap from '@/components/Logo/LogoMap.vue'
import { useMapsStore } from '@/store/maps'
import { storeToRefs } from 'pinia'

const { mapStyle } = storeToRefs(useMapsStore())
// import IconVolt from '@/components/Icon/Volt.vue'
// import IconCirclek from '@/components/Icon/Circlek-white.vue'
// import IconSaifa from '@/components/Icon/Saifa.vue'
// import IconRasta from '@/components/Icon/Rasta.vue'
// import IconIds from '@/components/Icon/Ids.vue'

// TODO: Make this either be a 'Location' and inherit its props or accept a 'Location' as a prop
const props = defineProps<Location>()

const verified = computed(() => {
  return props.meta?.some(
    (meta) => meta.type === 'trucktrust_verified' && meta.value === 'true'
  )
})

// function to check what type of location it is and what icon to use
// const locationType = (type: string) => {
//   switch (type) {
//     case 'circlek':
//       return IconCirclek
//     case 'saifa':
//       return IconSaifa
//     case 'rasta':
//       return IconRasta
//     case 'ids':
//       return IconIds
//     default:
//       return 'template'
//   }
// }

// const locationBg = (type: string) => {
//   switch (type) {
//     case 'circlek':
//       return 'bg-[#DC2625] p-1'
//     case 'saifa':
//       return 'bg-[#00763D]'
//     case 'rasta':
//       return 'bg-[#ffffff] p-1'
//     case 'ids':
//       return 'bg-[#ffffff] p-0.5'
//     default:
//       return 'bg-tt-pale'
//   }
// }
</script>

<template>
  <div
    :class="`h-2 w-2 rounded-full bg-tt-gray ${
      mapStyle === 'satellite-streets-v12' && 'ring-1 ring-white'
    }`"
  ></div>
  <div
    class="absolute bottom-6 left-1/2 flex w-12 -translate-x-1/2 flex-wrap [&>*:first-child]:mx-auto"
  >
    <Icon :location="props" :size="6" :priority="['icon-map']" />
    <!-- <component
      v-if="type"
      :is="locationType(type as string)"
      :class="`h-6 w-6 ${locationBg(type as string)}`"
    /> -->
    <LogoMap
      v-if="verified"
      class="h-6 w-6"
      :fillBg="'text-tt-green'"
      :fillTT="'#374650'"
    />
    <!-- <IconVolt class="h-6 w-6" />
    <IconVolt class="h-6 w-6" /> -->
  </div>
</template>
