<script lang="ts" setup>
import { useProfileStore } from '@/store/profile'
import TtTurnRadius from './TtTurnRadius.vue'

const profile = useProfileStore()
</script>

<template>
  <div
    class="mt-3.5 flex items-center justify-between text-sm text-tt-light-gray"
  >
    <div class="flex items-center gap-2">
      <div>
        {{
          `${profile.currentType?.name}${profile.currentType ? ' ' : ''}${
            profile.currentVehicle
              ? '(' + profile.currentVehicle?.reg_number + ')'
              : ''
          }`
        }}
      </div>
      <TtTurnRadius v-if="profile.totalLength" :radius="profile.totalLength" />
    </div>
    <div v-if="profile.totalWeight">{{ profile.totalWeight }} ton</div>
  </div>
</template>
