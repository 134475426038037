import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<any[]>([])

  const addNotification = (
    id: string,
    text: string,
    type: 'notification' | 'error' = 'notification',
    closable: boolean = false,
    background: boolean = false,
    blocking: boolean = false,
    completed: boolean = false
  ) => {
    removeNotification(id)
    notifications.value.push({
      id,
      text,
      type,
      closable,
      background,
      blocking,
      completed
    })
  }

  const removeNotification = (id: string) => {
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== id
    )
  }

  return { notifications, addNotification, removeNotification }
})
