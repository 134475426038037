<script lang="ts" setup>
import Icon from '@/components/Icon.vue'
import IconArrow from '@/components/Icon/Arrow.vue'
import { useI18n } from 'vue-i18n'

// TODO: needs reworking once we have all the real data

const { t } = useI18n({ useScope: 'global' })

const props = defineProps<{
  item: any
  type: string
}>()
</script>

<template>
  <component
    is="div"
    class="flex items-center justify-between rounded bg-tt-lighter-gray px-4 py-3.5 tracking-tighter text-tt-gray"
  >
    <div>
      <span>{{ t(`meta.${item.key}`) }}</span>
    </div>
    <div v-if="type === 'fuel'" class="flex items-center gap-3.5">
      <div class="font-mono">
        <span v-if="item.price">
          <template v-if="item.average">
            <span
              v-if="item.average < item.price"
              class="inline-block font-sans text-tt-red"
            >
              <IconArrow />
            </span>
            <span
              v-if="item.average >= item.price"
              class="inline-block rotate-180 font-sans text-tt-green"
            >
              <IconArrow />
            </span>
          </template>
          {{ item.price }}
          <span class="text-tt-gray/60"> kr/l</span>
        </span>
      </div>
      <Icon
        v-if="item.key === 'electric renewable'"
        :assets="[{ name: 'volt', type: 'icon-map', ext: '.svg' }]"
        :priority="['icon-map']"
      />
      <Icon
        v-else-if="item.key === 'electric fossil'"
        :assets="[{ name: 'volt_fo', type: 'icon-map', ext: '.svg' }]"
        :priority="['icon-map']"
      />
      <Icon
        v-else
        :assets="[{ name: item.key, type: 'icon-map', ext: '.svg' }]"
        :priority="['icon-map']"
      />
    </div>
    <div v-else>
      <Icon
        :assets="[{ name: item.key, type: 'icon-map', ext: '.svg' }]"
        :priority="['icon-map']"
      />
    </div>
  </component>
</template>
