<script setup lang="ts">
import TtContainer from '@/components/TtContainer.vue'
import TtButton from '@/components/TtButton.vue'
import ProfilePicture from '@/components/Icon/ProfilePicture.vue'
import IconClose from '@/components/Icon/Close.vue'
import LogoFullLight from '@/components/Logo/LogoFullLight.vue'
import ProfileSubHead from '@/components/ProfileSubHead.vue'
import { useRouter } from 'vue-router'
import TtAccordion from '@/components/TtAccordion.vue'
import TtParkingTile from '@/components/TtParkingTile.vue'
import { useI18n } from 'vue-i18n'
import { useAccountStore } from '@/store/account'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import SiteOwnerLocationDropdown from '../components/SiteOwnerLocationDropdown.vue'
import { isBefore } from 'date-fns'
import type { Location, Parking } from '@/location'
import { store } from '@/storehandler/tokens'
import { onMounted } from 'vue'
import { onBeforeMount } from 'vue'
import ParkingBookingConfirmation from '@/components/ParkingBookingConfirmation.vue'

const baseURL = import.meta.env.VITE_GATEWAY_URL

const { user } = storeToRefs(useAccountStore())

const { t } = useI18n({ useScope: 'global' })
const router = useRouter()

const today = new Date()

const locationsWithParkings = ref<Location[]>(
  user.value?.locations ? user.value.locations.filter((l) => l.parkings) : []
)

const bookings = ref<
  {
    id: string
    label: string
    available: boolean
    price: number
    coords: {
      lat?: number
      lng?: number
    }
    to: Date
    from: Date
    parking: Parking
    location: Location
  }[]
>([])
const isFetching = ref(false)

onBeforeMount(() => {
  if (user.value?.role !== 'siteowner') {
    isFetching.value = true
  }
})

onMounted(async () => {
  if (user.value?.role !== 'siteowner') {
    const response = await fetch(
      baseURL + '/user/' + user.value?.id + '/bookings',
      {
        headers: {
          'tt-token': store.get('tt-token')?.token
        }
      }
    ).then((res) => res.json())
    if (!response?.bookings || response.bookings.length === 0) {
      isFetching.value = false
      return
    }
    bookings.value = response.bookings.map((booking: any) => {
      return {
        id: booking.id,
        parking: booking.parking,
        location: booking.location,
        available: false,
        to: booking.to,
        from: booking.from
      }
    })
  }
  isFetching.value = false
})

const modalParking = ref<Parking | null>(null)
const modalLocation = ref<Location | null>(null)
const modalOpen = ref(false)

const openBookingModal = (id: string) => {
  const booking = bookings.value.find((b) => b.id === id)
  if (booking) {
    modalParking.value = booking.parking
    modalLocation.value = booking.location
    modalOpen.value = true
  }
}

const closeBookingModal = () => {
  modalOpen.value = false
  modalParking.value = null
  modalLocation.value = null
}
</script>

<template>
  <TtContainer
    @set-open="
      (val) => {
        val === false && router.push('/')
      }
    "
    :open="true"
    variant="popup-card-offset"
    class="z-40"
  >
    <template #offset>
      <div>
        <LogoFullLight class="mx-auto h-6" />
        <button class="absolute right-6 top-1" @click="router.push('/')">
          <IconClose class="h-3.5 w-3.5 text-white" />
        </button>
      </div>
    </template>
    <template #header>
      <div class="-mt-[4.75rem]">
        <ProfilePicture
          size="141x141"
          class="mx-auto block h-[141px] w-[141px] rounded-full object-cover"
        />
      </div>
      <div
        aria-hidden="true"
        class="absolute -bottom-6 left-0 z-10 block w-full px-1.5 pb-4 pt-2"
      >
        <div
          class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(white_70%,transparent)]"
        ></div>
        <div
          class="absolute inset-0 z-0 bg-gradient-to-b from-white from-40% to-[transparent]"
        ></div>
      </div>
    </template>
    <div class="mt-6 px-5 pb-10">
      <ProfileSubHead />
      <template v-if="user?.role !== 'siteowner'">
        <div class="mb-8 border-b border-black/10 pb-5 pt-5">
          <TtAccordion>
            <template #accordionButton>
              <h2 class="text-lg font-semibold leading-9 tracking-tight">
                {{ t('profile.bookings.current') }}
              </h2>
            </template>
            <div class="mt-3 grid gap-1.5">
              <div v-for="space in bookings" :key="space.id">
                <div v-if="isBefore(today, new Date(space.to))">
                  <TtParkingTile
                    :parking="space.parking"
                    :available="space.available"
                    as="button"
                    :on-my-bookings="true"
                    @click="openBookingModal(space.id)"
                  />
                </div>
              </div>
            </div>
          </TtAccordion>
        </div>
        <div>
          <TtAccordion :closed="true">
            <template #accordionButton>
              <h2 class="text-lg font-semibold leading-9 tracking-tight">
                {{ t('profile.bookings.history') }}
              </h2>
            </template>
            <div class="mt-3 grid gap-1.5">
              <div v-for="space in bookings" :key="space.id">
                <div v-if="isBefore(new Date(space.to), today)">
                  <TtParkingTile
                    :parking="space.parking"
                    :available="space.available"
                    as="div"
                    :on-my-bookings="true"
                  />
                </div>
              </div>
            </div>
          </TtAccordion>
        </div>
      </template>
      <template v-else>
        <div class="mb-8 border-b border-black/10 pb-5 pt-5">
          <div v-for="(location, i) in locationsWithParkings" class="mb-4">
            <SiteOwnerLocationDropdown :location="location" :i="i" />
          </div>
        </div>
      </template>
      <div class="mt-12 w-full">
        <TtButton
          :type="'tertiary'"
          :size="'large'"
          as="router-link"
          :to="{
            name: 'profile'
          }"
        >
          {{ t('profile.bookings.back') }}
        </TtButton>
      </div>
    </div>
  </TtContainer>
  <ParkingBookingConfirmation
    v-if="modalParking && modalLocation"
    :parking="modalParking"
    :location="modalLocation"
    :open="modalOpen"
    @close="closeBookingModal"
  />
</template>
