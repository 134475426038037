import { defineStore } from 'pinia'

export const useDebugStore = defineStore({
  id: 'debug',
  state: () => ({
    debug: false,
    debugCounter: 0
  }),
  getters: {},
  actions: {
    toggle() {
      this.debug = !this.debug
    },
    increment() {
      this.debugCounter++
      if (this.debugCounter > 10) {
        this.toggle()
        this.debugCounter = 0
      }
    }
  }
})
