<script setup lang="ts">
import { ref, watch } from 'vue'
import { useSidebarStore } from '@/store/sidebars'
import SidebarHeader from '@/components/Sidebar/SidebarHeader.vue'
import { storeToRefs } from 'pinia'
import TtContainer from '@/components/TtContainer.vue'
const sidebarStore = useSidebarStore()

// Store the open state to a ref on the component
const { open } = storeToRefs(sidebarStore)
// Add referneces to the elemnents that we want to scroll
const primaryPanelScrollElement = ref<undefined | HTMLElement>(undefined)

// Watch the open state and scroll the elements to the top if the open state is false (closing)
watch(open, () => {
  if (!open.value) {
    primaryPanelScrollElement.value?.scrollTo(0, 0)
  }
})
</script>

<template>
  <TtContainer variant="overlay-dark" :open="open">
    <div
      ref="primaryPanelScrollElement"
      :class="`${
        open ? 'overflow-auto pt-safe-top' : 'overflow-hidden'
      } flex h-full flex-1 flex-col pb-10 sm:w-full`"
    >
      <div>
        <SidebarHeader>
          <template #primarySidebarButton>
            <slot name="primarySidebarButton" />
          </template>
        </SidebarHeader>
        <slot />
      </div>
    </div>
  </TtContainer>
</template>
