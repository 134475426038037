<script setup lang="ts">
import type { Location } from '../location'
import SiteOwnerParkingBookings from './SiteOwnerParkingBookings.vue'
import Spinner from '../components/Icon/Spinner.vue'
import TtAccordion from '../components/TtAccordion.vue'
import { ref } from 'vue'

defineProps<{ location: Location; i: number }>()

const isFetching = ref(true)
const count = ref(0)

const showCount = (actualCount: number) => {
  count.value = actualCount
  isFetching.value = false
}
</script>
<template>
  <TtAccordion :closed="i > 0">
    <template #accordionButton>
      <div class="flex w-full justify-between">
        <h2 class="text-lg font-semibold leading-9 tracking-tight">
          {{ location.address?.street ? location.address.street : location.name
          }}{{ location.address?.city ? ', ' + location.address.city : '' }}
        </h2>
        <div
          class="relative flex items-center justify-center font-medium transition-colors"
        >
          <span
            v-if="!isFetching"
            class="h-6 min-w-6 rounded-sm border border-tt-gray px-[6.5px] py-0.5"
          >
            {{ count }}
          </span>
          <span
            class="flex h-6 min-w-6 items-center justify-center rounded-sm border border-tt-gray px-[6.5px] py-0.5"
            v-else
          >
            <Spinner class="h-3 w-3" />
          </span>
        </div>
      </div>
    </template>
    <div class="mt-3 grid gap-1.5">
      <SiteOwnerParkingBookings :location="location" @hasFetched="showCount" />
    </div>
  </TtAccordion>
</template>
