<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 16"
  >
    <path
      fill="#374650"
      d="M20 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2Zm-2 2H2V2h16v2ZM2 7h16v7H2V7Z"
    />
  </svg>
</template>
