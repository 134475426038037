<script lang="ts" setup>
import { useNotificationsStore } from '@/store/notifications'
import { onMounted } from 'vue'

const notificationsStore = useNotificationsStore()

// onMounted(() => {
//   setTimeout(() => {
//     notificationsStore.addNotification('localized', 'Din plats har lokaliserats', 'notification', false, false, false, true)
//   }, 2000)
//   setTimeout(() => {
//     notificationsStore.removeNotification('localized')
//   }, 4000)
// })

// DEMO - NOTIFICATIONS

// const notificationsStore = {
//   notifications: [
//     {
//       text: 'Länkadress har kopierats till urklipp',
//       type: 'notification',
//       closable: false,
//       blocking: false,
//       background: false,
//       completed: false
//     },
//     {
//       text: 'Något gick fel!',
//       type: 'danger',
//       closable: false,
//       blocking: false,
//       background: false,
//       completed: false
//     },
//     {
//       text: 'Registrerar din bokning',
//       type: 'notification',
//       closable: false,
//       blocking: false,
//       background: true,
//       completed: false
//     },
//     {
//       text: 'Hämtar din position',
//       type: 'notification',
//       closable: false,
//       blocking: true,
//       background: false,
//       completed: false
//     },
//     {
//       text: 'Ändringar är sparade',
//       type: 'notification',
//       closable: false,
//       blocking: false,
//       background: false,
//       completed: true
//     },
//     {
//       text: 'Position kunde inte fastställas',
//       type: 'notification',
//       closable: true,
//       blocking: false,
//       background: false,
//       completed: false
//     },
//     {
//       text: 'Något gick fel!',
//       type: 'dager',
//       closable: true,
//       blocking: false,
//       background: false,
//       completed: false
//     }
//   ]
// }
</script>

<template>
  <div class="relative">
    <div
      class="pointer-events-none absolute inset-0 z-50 flex items-start justify-center px-2.5 pt-[calc(0.625rem+env(safe-area-inset-top))]"
    >
      <transition-group
        name="notification-list"
        tag="ul"
        aria-live="polite"
        class="grid w-full max-w-sm gap-1.5"
      >
        <li
          v-for="(notification, index) in notificationsStore.notifications.reverse()"
          :key="index"
          :class="`pointer-events-auto w-full rounded text-lg font-semibold tracking-tight text-white shadow-login backdrop-blur-sm ${
            notification.type === 'danger' ? 'bg-tt-pure-red' : 'bg-black/70'
          } flex h-[3.25rem] items-center pl-4 pr-3.5 ${
            notification.closable ||
            notification.blocking ||
            notification.background ||
            notification.completed
              ? 'justify-between'
              : 'justify-center'
          }`"
        >
          <span>{{ notification.text }}</span>
          <button
            class="flex h-6 w-6 items-center justify-center"
            v-if="notification.closable"
            @click="notificationsStore.removeNotification(notification.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 14 14"
              :class="`${
                notification.type === 'danger'
                  ? 'text-tt-white'
                  : 'text-[#8B9398]'
              }`"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 1 1 13M1 1l12 12"
              />
            </svg>
          </button>
          <span v-else-if="notification.blocking">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              class="animate-spin"
            >
              <path
                fill="#00E1A5"
                fill-rule="evenodd"
                d="M14.535 20.758a9.115 9.115 0 1 1 6.555-8.076l2.878.206c.021-.293.032-.59.032-.888 0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12a12 12 0 0 0 3.47-.51l-.935-2.732Z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span v-else-if="notification.background">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              class="animate-spin"
            >
              <path
                fill="#C9C9C9"
                fill-rule="evenodd"
                d="M14.535 20.758a9.115 9.115 0 1 1 6.555-8.076l2.878.206c.021-.293.032-.59.032-.888 0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12a12 12 0 0 0 3.47-.51l-.935-2.732Z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span v-else-if="notification.completed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="12" fill="#00E1A5" />
              <path
                fill="#000"
                d="M10.243 16.485 6 12.242l1.414-1.414 2.829 2.829L15.899 8l1.415 1.414-7.071 7.071Z"
              />
            </svg>
          </span>
        </li>
      </transition-group>
    </div>
    <slot />
  </div>
</template>

<style scoped>
.notification-list-enter-active {
  transition: all 0.3s ease;
}
.notification-list-leave-active {
  transition: all 0.3s ease;
}
.notification-list-enter-from {
  opacity: 0;
  transform: translateY(-100%);
}
.notification-list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
