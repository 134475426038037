<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useSidebarStore } from '@/store/sidebars';
const { open } = storeToRefs(useSidebarStore());

defineProps<{
  dark?: boolean
}>()
</script>

<template>
  <svg
    v-if="!open"
    class="w-6 h-6"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.412 0H3.56609C1.59708 0 0 1.59708 0 3.56609V20.4339C0 22.4029 1.59708 24 3.56609 24H20.4339C22.4029 24 24 22.4029 24 20.4339V3.56609C23.9781 1.59708 22.381 0 20.412 0Z"
      :fill="dark ? '#36454F' : '#E6FFF5'"
    />
    <rect
      x="2.57227"
      y="2.57241"
      width="9.43218"
      height="18.8644"
      rx="1"
      :fill="dark ? '#E6FFF5' : '#36454F'"
    />
  </svg>
  <svg
    v-else
    class="w-6 h-6"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.412 0H3.56609C1.59708 0 0 1.59708 0 3.56609V20.4339C0 22.4029 1.59708 24 3.56609 24H20.4339C22.4029 24 24 22.4029 24 20.4339V3.56609C23.9781 1.59708 22.381 0 20.412 0Z"
      :fill="dark ? '#36454F' : '#E6FFF5'"
    />
    <rect
      x="3"
      y="17"
      width="18"
      height="4"
      rx="1"
      :fill="dark ? '#E6FFF5' : '#36454F'"
    />
  </svg>
</template>
