export default class StoreHandler {
  parse: (value: string) => any
  stringify: (value: any) => string

  constructor(
    stringify?: (value: any) => string,
    parse?: (value: string) => any
  ) {
    this.parse = parse ?? JSON.parse
    this.stringify = stringify ?? JSON.stringify
  }

  set(key: string, value: any) {
    try {
      localStorage.setItem(key, this.stringify(value))
    } catch (e) {
      console.error(e)
    }
  }
  get(key: string) {
    try {
      return this.parse(localStorage.getItem(key) || 'null')
    } catch (e) {
      return null
    }
  }
  remove(key: string) {
    localStorage.removeItem(key)
  }
}
